/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 * NewtonGreen Technologies Pty.Ltd.
 * Level 4, 175 Scott St.
 * Newcastle, NSW, 2300
    * Australia
    *
 * E - mail: support @newtongreen.com
 * Tel: (02) 4925 5288
    * Fax: (02) 4925 3068
        *
 * All Rights Reserved.
 * ---------------------------------------------------------------------------------
 * /

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Tooltip, TextField, TableRow, Table, TableCell, TableBody, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    PatientSummaryList,
    PatientContext,
    PatientValidationContext,
    PatientBreadcrumbs,
    PatientInformation,
    PatientInformationFn,
    ProgressButton,
    InstitutionContext,
    MasterGroupContext,
    CollaboratingGroupContext,
    RouteLoading,
    ValidationResultType,
    useSnackbar,
    IPatientSummaryActionProps,
    OnlinePatientManagementContext,
    IPatient
} from '@ngt/opms';

import Alert from '@material-ui/lab/Alert';

import AlertTitle from '@material-ui/lab/AlertTitle';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePermissionsByIds } from '@ngt/opms-trogapi';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { JsonServiceClient } from '@servicestack/client';
import { TreatmentType } from '../api/dtos';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientSummaryParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IPatientSummaryProps {
    showAdminPage?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        paddingBottom: theme.spacing(3),

        '&:last-child': {
            textAlign: 'right'
        },
        '&:nth-last-child(2)': {
            textAlign: 'right'
        },
        '&:first-child': {
            textAlign: 'left'
        },
    },
    buttonGroup: {
        padding: theme.spacing(3, 0, 0, 0),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    message: {
        width: '100%'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const UpdateReasons = [
    "",
    "Eligibility not met",
    "Withdrew Consent",
    "Site Restrictions",
    "Other"
];

const UpdateCriteria = [
    "",
    "No histologically or radiologically diagnosis of HCC",
    "HCC >8cm",
    "Multifocal HCC, macrovascular invasion and/or extrahepatic disease",
    "Prior treatment for HCC within the last 2 years",
    "Not suitable for percutaneous thermal ablation and/or transarterial therapies",
    "Suitable for liver resection (and not declined)",
    "Planned for liver transplantation",
    "Child Pugh >B7",
    "ECOG performance status >2",
    "Inadequate organ and marrow function and/or clotting factors",
    "Clinically evident ascites or hepatic encephalopathy present",
    "Prior abdominal RT",
    "Untreated Hepatitis B or C",
    "Known or prior invasive malignancy excluding them from trial participation",
    "Pregnant"
];

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientUpdate,
    Dtos.Permission.OpmsPatientImpersonate,
    Dtos.Permission.OpmsPatientAccountManagement
];

const patientCaption: PatientInformationFn = (patient) => (patient as Dtos.Patient)?.initials
const patientStateCaption: PatientInformationFn = (patient) => {
    let stateCaption = DateTime.fromISO((patient as Dtos.Patient)?.randomisationDate ?? patient?.enteredDate ?? DateTime.local().toISO({ includeOffset: false })).toFormat('dd/MM/yyyy');

    return stateCaption;
}

const PatientSummary: React.FunctionComponent<IPatientSummaryProps> = ({
    showAdminPage
}) => {
    const classes = useStyles();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [reviewModalOpen, setReviewModalOpen] = React.useState(false);
    const [reassignModalOpen, setReassignModalOpen] = React.useState(false);
    const [randomisationModalOpen, setRandomisationModalOpen] = React.useState(false);
    const [ineligibleModalOpen, setIneligibleModalOpen] = React.useState(false);

    const [patientStateUpdateReason, setPatientStateUpdateReason] = React.useState("");
    const [patientIneligibleReason, setPatientIneligibleReason] = React.useState(0);
    const [patientIneligibleCriteria, setPatientIneligibleCriteria] = React.useState(0);
    const [patientIneligibleExtra, setPatientIneligibleExtra] = React.useState("");

    const { enqueueSnackbar } = useSnackbar();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions, saveState } = React.useContext(PatientContext);

    const { validation, actions: validationActions, loadState } = React.useContext(PatientValidationContext);

    const patientValid = validation?.result === ValidationResultType.Valid;

    const saving = saveState.state === RequestState.Pending || loadState.state === RequestState.Pending;

    const updatePatientState = React.useCallback(async (patientStateId: Dtos.PatientStateType, waivered?: boolean) => {
        var patientReason = patientStateUpdateReason;

        if (!(patientIneligibleReason == 0 || patientIneligibleReason == null)) {
            patientReason = UpdateReasons[patientIneligibleReason];
            if (patientIneligibleCriteria != 0) {
                patientReason += " (" + UpdateCriteria[patientIneligibleCriteria] + ")";
            }
            if (patientIneligibleExtra != "") {
                patientReason += ": " + patientIneligibleExtra;
            }
        }

        await actions.asyncSave(new Dtos.Patient({ ...patient, patientStateId: patientStateId, previousPatientStateId: patient?.patientStateId, patientStateComment: patientReason, waivered: waivered }));
        validationActions.load();
    }, [actions, validationActions, patient, patientStateUpdateReason, patientIneligibleReason, patientIneligibleCriteria, patientIneligibleExtra]);

    const onRegisterClick = React.useCallback(async () => {
        setRandomisationModalOpen(false);

        try {
            await updatePatientState(Dtos.PatientStateType.Randomised, (!patientValid ? true : undefined));

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Randomised
                    </AlertTitle>
                    The participant was successfully Randomised.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Randomised
                    </AlertTitle>
                    An error occurred while attempting to randomise the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, setRandomisationModalOpen, enqueueSnackbar, patientValid]);

    const onReviewClick = React.useCallback(async () => {
        setReviewModalOpen(false);

        try {
            await updatePatientState(Dtos.PatientStateType.UnderReview);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Submitted For Review
                    </AlertTitle>
                    The participant was successfully submitted for eligibility review.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Submitted For Review
                    </AlertTitle>
                    An error occurred while attempting to submit the participant for eligibility review.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, setRandomisationModalOpen, enqueueSnackbar]);

    const onIneligibleClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Ineligible);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Marked As Ineligible
                    </AlertTitle>
                    The participant was successfully marked ineligible.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Marked As Ineligible
                    </AlertTitle>
                    An error occurred while attempting to mark the participant as ineligible.
                </>,
                { variant: 'critical' }
            );
            setPatientStateUpdateReason('');
        }
    }, [updatePatientState, enqueueSnackbar]);

    const onRestoreClick = React.useCallback(async () => {
        try {
            await updatePatientState((patient as Dtos.Patient)?.previousPatientStateId ?? Dtos.PatientStateType.Prerandomised);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Restored
                    </AlertTitle>
                    The participant was successfully restored.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Restored
                    </AlertTitle>
                    An error occurred while attempting to restore the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar, patient]);

    const onReassignClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Prerandomised);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Reassigned To Site
                    </AlertTitle>
                    The participant was successfully reassigned to site.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Participant Not Reassigned To Site
                    </AlertTitle>
                    An error occurred while attempting to reassign the participant.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar, patient]);

    const toggleReviewModal = React.useCallback(() => {
        setReviewModalOpen(state => !state);
    }, [setReviewModalOpen]);

    const toggleReassignModal = React.useCallback(() => {
        setReassignModalOpen(state => !state);
    }, [setReassignModalOpen])

    const toggleRegistrationModal = React.useCallback(() => {
        setRandomisationModalOpen(state => !state);
    }, [setRandomisationModalOpen]);

    const toggleIneligibleModal = React.useCallback(() => {
        setIneligibleModalOpen(state => !state);
    }, [setIneligibleModalOpen]);

    const [
        [
            canAdministerOpms,
            canUpdatePatient,
            canImpersonatePatient,
            canManagePatientAccount
        ],
        permissionLoadState
    ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    //const patientIneligible = validation?.result === ValidationResultType.Ineligible;
    const patientError = validation?.result === ValidationResultType.Error;

    const canReassign = !saving;
    const canReview = !saving && (patientValid || ((validation?.result ?? ValidationResultType.Critical) <= ValidationResultType.Error && canAdministerOpms));
    const canRegister = !saving && (patientValid || ((validation?.result ?? ValidationResultType.Critical) <= ValidationResultType.Error && canAdministerOpms));

    return (
        <>
            <PatientBreadcrumbs />
            <div
                className={classes.container}
            >
                <PatientInformation
                    patientCaption={patientCaption}
                    patientStateCaption={patientStateCaption}
                />
                <br />
                <br />
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        sm
                        className={classes.title}
                    >
                        <Typography
                            variant="h1"
                            color="secondary"
                        >
                            Participant Summary
                        </Typography>
                    </Grid>
                </Grid>

                {
                    (patient as Dtos.Patient)?.waivered === true && (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
                            severity="info"
                            classes={{
                                message: classes.message
                            }}
                        >
                            <AlertTitle>
                                <strong>
                                    Participant Waivered
                                </strong>
                            </AlertTitle>

                            <Table style={{ border: '1px solid #dee2e6' }}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ border: '1px solid #dee2e6', padding: '0.5rem', width: '20%' }}><strong>Reason</strong></TableCell>
                                        <TableCell style={{ border: '1px solid #dee2e6', padding: '0.5rem' }}>{(patient as Dtos.Patient)?.patientStateComment}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ border: '1px solid #dee2e6', padding: '0.5rem' }}><strong>Date of Waiver </strong></TableCell>
                                        <TableCell style={{ border: '1px solid #dee2e6', padding: '0.5rem' }}>{(patient as Dtos.Patient).randomisationDate ? DateTime.fromISO((patient as Dtos.Patient).randomisationDate as string).toFormat('dd/MM/yyyy') : ''}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Alert>
                    )
                }

                <PatientSummaryList />

                <Dialog
                    open={reviewModalOpen}
                    onClose={toggleReviewModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Submit Participant For Review?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The participant has outstanding {patientError ? 'errors' : 'ineligibility warnings'} that should be corrected.
                            <br /><br />
                            Submit the participant for eligibility review?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleReviewModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={onReviewClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="xs"
                    open={ineligibleModalOpen}
                    onClose={toggleIneligibleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Mark Participant as ineligible?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to mark participant {patient?.studyNumber} as ineligible?
                            <br /><br />
                            <strong>Reason</strong>
                        </DialogContentText>
                        <FormControl fullWidth>
                            <InputLabel id="reason">Please select</InputLabel>
                            <Select
                                fullWidth={true}
                                label="Reason"
                                labelId="reason"
                                onChange={(e) => { setPatientIneligibleReason(e.target.value as number); }}
                            >
                                <MenuItem value="">&nbsp;</MenuItem>
                                <MenuItem value={1}>Eligibility not met</MenuItem>
                                <MenuItem value={2}>Withdrew Consent</MenuItem>
                                <MenuItem value={3}>Site Restrictions</MenuItem>
                                <MenuItem value={4}>Other</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth style={{ "marginTop": "10px" }}>
                            <InputLabel id="criteria">Eligibility not met due to</InputLabel>
                            <Select
                                disabled={patientIneligibleReason !== 1}
                                fullWidth={true}
                                label="Criteria"
                                labelId="criteria"
                                onChange={(e) => { setPatientIneligibleCriteria(e.target.value as number); }}
                            >
                                <MenuItem value="">&nbsp;</MenuItem>
                                <MenuItem value={1}>{UpdateCriteria[1]}</MenuItem>
                                <MenuItem value={2}>{UpdateCriteria[2]}</MenuItem>
                                <MenuItem value={3}>{UpdateCriteria[3]}</MenuItem>
                                <MenuItem value={4}>{UpdateCriteria[4]}</MenuItem>
                                <MenuItem value={5}>{UpdateCriteria[5]}</MenuItem>
                                <MenuItem value={6}>{UpdateCriteria[6]}</MenuItem>
                                <MenuItem value={7}>{UpdateCriteria[7]}</MenuItem>
                                <MenuItem value={8}>{UpdateCriteria[8]}</MenuItem>
                                <MenuItem value={9}>{UpdateCriteria[9]}</MenuItem>
                                <MenuItem value={10}>{UpdateCriteria[10]}</MenuItem>
                                <MenuItem value={11}>{UpdateCriteria[11]}</MenuItem>
                                <MenuItem value={12}>{UpdateCriteria[12]}</MenuItem>
                                <MenuItem value={13}>{UpdateCriteria[13]}</MenuItem>
                                <MenuItem value={14}>{UpdateCriteria[14]}</MenuItem>
                                <MenuItem value={15}>{UpdateCriteria[15]}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label="Additional Details"
                            multiline={true}
                            fullWidth={true}
                            variant={'outlined'}
                            rows={10}
                            style={{ "marginTop": "20px" }}
                            inputProps={{
                                onBlur: (e) => { setPatientIneligibleExtra(e.currentTarget.value) }
                            }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleIneligibleModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={onIneligibleClick} disabled={!setPatientStateUpdateReason} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={randomisationModalOpen}
                    onClose={toggleRegistrationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Randomise Participant?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The participant has outstanding {patientError ? 'errors' : 'ineligibility warnings'} that should be corrected.
                            <br /><br />
                            Are you sure you want to randomise the participant?
                            <br /><br />
                            <strong>Reason</strong>
                        </DialogContentText>
                        <TextField
                            multiline={true}
                            fullWidth={true}
                            variant={"outlined"}
                            rows={10}
                            inputProps={{
                                onBlur: (e) => { setPatientStateUpdateReason(e.currentTarget.value) }
                            }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleRegistrationModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={onRegisterClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={reassignModalOpen}
                    onClose={toggleReassignModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Reassign Participant To Site?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to reassign participant {patient?.studyNumber} back to site?
                            <br /><br />
                            <strong>Reason</strong>
                        </DialogContentText>
                        <TextField
                            multiline={true}
                            fullWidth={true}
                            variant={"outlined"}
                            rows={10}
                            inputProps={{
                                onBlur: (e) => { setPatientStateUpdateReason(e.currentTarget.value) }
                            }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleReassignModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={onReassignClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <div
                    className={classes.buttonGroup}
                >
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Prerandomised && canUpdatePatient && patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onReviewClick}
                                disabled={!canReview}
                            >
                                Submit for eligibility review
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Prerandomised && canUpdatePatient && !patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleReviewModal}
                                disabled={!canReview}
                            >
                                Submit for eligibility review
                            </ProgressButton>
                        )
                    }

                    {
                        patient?.patientStateId === Dtos.PatientStateType.UnderReview && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleReassignModal}
                                disabled={!canReassign}
                            >
                                Reassign to site
                            </ProgressButton>
                        )
                    }

                    {
                        patient?.patientStateId === Dtos.PatientStateType.UnderReview && canAdministerOpms && patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRegisterClick}
                                disabled={!canRegister}
                            >
                                Randomise
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.UnderReview && canAdministerOpms && !patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleRegistrationModal}
                                disabled={!canRegister}
                            >
                                Randomise
                            </ProgressButton>
                        )
                    }
                    {
                        ((patient?.patientStateId === Dtos.PatientStateType.Prerandomised && canUpdatePatient) || (patient?.patientStateId === Dtos.PatientStateType.UnderReview && canAdministerOpms)) && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleIneligibleModal}
                            >
                                Mark As Ineligible
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Ineligible && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRestoreClick}
                            >
                                Restore Participant
                            </ProgressButton>
                        )
                    }
                </div>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientSummary;