/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    StaticGrid,
    StaticGridRow,
    IStaticGridHeaderProps,
    IStaticGridCellProps,
    FormLabel,
    Input
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IBloodsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 2, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const TWO_DIGIT_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const FOUR_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const FOUR_DIGIT_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

export const FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES = {
    decimalScale: 2,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 10000) {
            return true;
        }

        return false;
    }
}

const chemistryHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'test',
        content: 'Test',
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'value',
        content: 'Value',
        width: '50%',
        minWidth: 200
    },
];

const bilirubinRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'totalBilirubin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'totalBilirubin'
    }
];

const altRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'alt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'alt'
    }
];

const astRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ast'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'ast'
    }
];

const alpRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'alp'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'alp'
    }
];

const ldhRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ldh'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'ldh'
    }
];

const ggtRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'ggt'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">U/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'ggt'
    }
];

const albuminRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'albumin'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">g/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'albumin'
    }
];

const sodiumRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'sodium'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'sodium'
    }
];

const creatinineRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'creatinine'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">&micro;mol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={FOUR_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
            />
        ),
        fieldName: 'creatinine'
    }
];

const afpRow: IStaticGridCellProps[] = [
    {
        columnName: 'test',
        content: (
            <FormLabel />
        ),
        fieldName: 'afp'
    },
    {
        columnName: 'value',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Typography color="textSecondary">mmol/L&nbsp;</Typography>
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_INTEGER_TWO_DECIMAL_PLACES}
            />
        ),
        fieldName: 'afp'
    }
];



const protocolVersion1 = ({ lookups, formState }: ICrfConditionParameters<Dtos.Bloods>) => formState?.values.protocolVersion === (window as any).PROTOCOL_VERSION_1_ID;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const Bloods: React.FunctionComponent<IBloodsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.Bloods}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Clinical Chemistry
                    </Typography>
                    <Field
                        name="chemistryAssessmentDate"
                        component={KeyboardDatePicker}
                    />
                    <StaticGrid
                        headers={chemistryHeader}
                    >
                        <StaticGridRow
                            cells={bilirubinRow}
                        />
                        <StaticGridRow
                            cells={altRow}
                        />
                        <StaticGridRow
                            cells={astRow}
                        />
                        <StaticGridRow
                            cells={alpRow}
                        />
                        <StaticGridRow
                            cells={ggtRow}
                        />
                        <StaticGridRow
                            cells={albuminRow}
                        />
                        <StaticGridRow
                            cells={sodiumRow}
                        />
                        <StaticGridRow
                            cells={creatinineRow}
                        />
                        <StaticGridRow
                            cells={afpRow}
                        />
                    </StaticGrid>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Haematology
                    </Typography>

                    <Field
                        name="haematologyAssessmentDate"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="haemoglobin"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    g/dL&nbsp;
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        name="plateletCount"
                        component={Numeric}
                        numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        name="whiteCellCount"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        name="neutrophilCount"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Field
                        name="lymphocyteCount"
                        component={Numeric}
                        numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">x10<sup>9</sup>/l&nbsp;</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h3"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Coagulation
                    </Typography>
                    <Field
                        name="coagulationAssessmentDate"
                        component={KeyboardDatePicker}
                    />
                    <CrfCondition
                        type={Dtos.General}
                        mode="Show"
                        condition={protocolVersion1}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="prothrombinTime"
                            component={Numeric}
                            numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">sec&nbsp;</Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Field
                            name="partialThromboplastinTime"
                            component={Numeric}
                            numberFormat={THREE_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">sec&nbsp;</Typography>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </CrfCondition>
                    <Field
                        name="internationalNormalisedRatio"
                        component={Numeric}
                        numberFormat={TWO_DIGIT_INTEGER_ONE_DECIMAL_PLACE}
                    />
                </FieldGroup>
                <Field
                    name="patientAntiCoagulants"
                    component={ToggleButtonGroup}
                />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Bloods;
