/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the report table component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as React from 'react';
import {
    ICollapsibleTableProps,
    CollapsibleTable,
} from '@ngt/opms';
import { IReportParam, ReportExportFormat } from '../../utils/createReportUrl';

/*
 * ---------------------------------------------------------------------------------
 * Types
 * ---------------------------------------------------------------------------------
 */

export type GetReportParamsFunction = () => IReportParam[];

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IReportItem {
    reportTitle: string;
    reportFile: string;
    reportFormat?: ReportExportFormat;
    reportParams: IReportParam[];
}

const ReportsTable =({
    data,
    columns,
    onClick,
    ...tableProps
}) => {
    return (
        <>
            <CollapsibleTable
                title='Reports'
                entityName='Reports'
                loading={false}
                columns={columns}
                onRowClick={onClick}
                data={data}
                options={{
                    rowStyle: {
                        overflowWrap: 'break-word'
                    },
                    headerStyle: {
                        overflowWrap: 'break-word'
                    },
                    pageSize: 25,
                    pageSizeOptions: [25]
                }}
                {...tableProps}
            />
        </>
    );
};

export default ReportsTable;