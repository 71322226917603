
export const languageCodes = [
    { id: 11, value: 'Celtic', order: 1, type: 11 },
    { id: 12, value: 'English', order: 2, type: 12 },
    { id: 13, value: 'German and Related Languages', order: 3, type: 13 },
    { id: 14, value: 'Dutch and Related Languages', order: 4, type: 14 },
    { id: 15, value: 'Scandinavian', order: 5, type: 15 },
    { id: 16, value: 'Finnish and Related Languages', order: 6, type: 16 },
    { id: 21, value: 'French', order: 7, type: 21 },
    { id: 22, value: 'Greek', order: 8, type: 22 },
    { id: 23, value: 'Iberian Romance', order: 9, type: 23 },
    { id: 24, value: 'Italian', order: 10, type: 24 },
    { id: 25, value: 'Maltese', order: 11, type: 25 },
    { id: 29, value: 'Other Southern European Languages', order: 12, type: 29 },
    { id: 31, value: 'Baltic', order: 13, type: 31 },
    { id: 33, value: 'Hungarian', order: 14, type: 33 },
    { id: 34, value: 'East Slavic', order: 15, type: 34 },
    { id: 35, value: 'South Slavic', order: 16, type: 35 },
    { id: 36, value: 'West Slavic', order: 17, type: 36 },
    { id: 39, value: 'Other Eastern European Languages', order: 18, type: 39 },
    { id: 41, value: 'Iranic', order: 19, type: 41 },
    { id: 42, value: 'Middle Eastern Semitic Languages', order: 20, type: 42 },
    { id: 43, value: 'Turkic', order: 21, type: 43 },
    { id: 49, value: 'Other Southwest and Central Asian Languages', order: 1, type: 49 },
    { id: 51, value: 'Dravidian', order: 22, type: 51 },
    { id: 52, value: 'Indo-Aryan', order: 23, type: 52 },
    { id: 59, value: 'Other Southern Asian Languages', order: 24, type: 59 },
    { id: 61, value: 'Burmese and Related Languages', order: 25, type: 61 },
    { id: 62, value: 'Hmong-Mien', order: 26, type: 62 },
    { id: 63, value: 'Mon-Khmer', order: 27, type: 63 },
    { id: 64, value: 'Tai', order: 28, type: 64 },
    { id: 65, value: 'Southeast Asian Austronesian Languages', order: 29, type: 65 },
    { id: 69, value: 'Other Southeast Asian Languages', order: 30, type: 69 },
    { id: 71, value: 'Chinese', order: 31, type: 71 },
    { id: 72, value: 'Japanese', order: 32, type: 72 },
    { id: 73, value: 'Korean', order: 33, type: 73 },
    { id: 79, value: 'Other Eastern Asian Languages', order: 34, type: 79 },
    { id: 81, value: 'Arnhem Land and Daly River Region Languages', order: 35, type: 81 },
    { id: 82, value: 'Yolngu Matha', order: 36, type: 82 },
    { id: 83, value: 'Cape York Peninsula Languages', order: 37, type: 83 },
    { id: 84, value: 'Torres Strait Island Languages', order: 38, type: 84 },
    { id: 85, value: 'Northern Desert Fringe Area Languages', order: 39, type: 85 },
    { id: 86, value: 'Arandic', order: 40, type: 86 },
    { id: 87, value: 'Western Desert Languages', order: 41, type: 87 },
    { id: 88, value: 'Kimberley Area Languages', order: 42, type: 88 },
    { id: 89, value: 'Other Australian Indigenous Languages', order: 43, type: 89 },
    { id: 91, value: 'American Languages', order: 44, type: 91 },
    { id: 92, value: 'African Languages', order: 45, type: 92 },
    { id: 93, value: 'Pacific Austronesian Languages', order: 46, type: 93 },
    { id: 94, value: 'Oceanian Pidgins and Creoles', order: 47, type: 94 },
    { id: 95, value: 'Papua New Guinea Languages', order: 48, type: 95 },
    { id: 96, value: 'Invented Languages', order: 49, type: 96 },
    { id: 97, value: 'Sign Languages', order: 50, type: 97 }
];