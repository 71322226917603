/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    FormGrid,
    useFormState,
    IFormGridCell,
    Input,
    CtcaeSelector,
    Masked,
    getParentPropertyPath
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { get } from 'lodash-es';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../utils/numberFormat';
import { CheckBox } from '@servicestack/react';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDiseaseAssessmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(2, 3),
        color: theme.palette.primary.main
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const UK_DATE = {
    mask: [/[0-3U]/, /[0-9K]/, '/', /[0-1U]/, /[0-9K]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const locationOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => GetFieldLookupItem(lookups, 'primaryTargetLesion', formState?.values.primaryTargetLesion)?.type === Dtos.LookupSegmentType.Other;
const mrecistNotApplicableNotChecked = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.mrecistNotApplicable !== true;
const recistNotApplicableNotChecked = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiseaseAssessment>) => formState?.values.recistNotApplicable !== true;

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const DiseaseAssessment: React.FunctionComponent<IDiseaseAssessmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DiseaseAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
            <Typography variant="h2" className={classes.subHeading}>
                Hepatic Imaging
            </Typography>
            <Field
                name="assessmentDate"
                component={KeyboardDatePicker}
            />
            <Field
                    name="scanType"
                    label={
                        <>
                            <Typography>Scan Type</Typography>
                            <i>(Scan type should be consistent throughout the trial for mRECIST assessment)</i>
                        </>
                    }
                component={ToggleButtonGroup}
            />
            <Field
                name="contrast"
                component={ToggleButtonGroup}
            />
            <FieldGroup>
                <Field
                name="primaryTargetLesion"
                component={Select}
                />
                <CrfCondition
                    type={Dtos.DiseaseAssessment}
                    mode="Enable"
                    condition={locationOther}
                    subscription={{ values: true }}
                >
                    <Field
                        name="primaryTargetLesionOther"
                        component={Text}
                    />
                </CrfCondition>
            </FieldGroup>
            <Field
                name="arterialPhaseEnhancement"
                component={Select}
            />
            <Field
                name="liverImagingReporting"
                component={Select}
            />
                <FieldGroup>
                <CrfCondition
                    type={Dtos.DiseaseAssessment}
                    mode="Enable"
                    condition={mrecistNotApplicableNotChecked}
                    subscription={{ values: true }}
                >
                    <Field
                        name="mrecist"
                        label={
                            <Typography>
                                <strong>mRECIST measurement</strong>: Longest diameter <strong><i>arterially hyperenhancing</i></strong> tumour
                            </Typography>
                        }
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">mm</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </CrfCondition>
                <Field
                    name="mrecistNotApplicable"
                    label={' '}
                    component={Checkbox}
                />
            </FieldGroup>
            <FieldGroup>
                <CrfCondition
                    type={Dtos.DiseaseAssessment}
                    mode="Enable"
                    condition={recistNotApplicableNotChecked}
                    subscription={{ values: true }}
                >
                    <Field
                        name="recist"
                        label={
                            <Typography>
                                <strong>RECIST v1.1 measurement</strong>: Longest diameter target lesion
                            </Typography>
                        }
                        component={Numeric}
                        numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">mm</Typography>
                                </InputAdornment>
                            )
                        }}
                    />
                </CrfCondition>
                <Field
                    name="recistNotApplicable"
                    label={' '}
                    component={Checkbox}
                />
            </FieldGroup>
            <Field
                name="extrahepaticHCCmetastasis"
                component={ToggleButtonGroup}
            />
            <Typography variant="h2" className={classes.subHeading}>
                Chest Imaging
            </Typography>
            <Field
                name="ctChestDate"
                component={KeyboardDatePicker}
            />
            <Field
                name="ctHCCmetastases"
                component={ToggleButtonGroup}
            />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DiseaseAssessment;
