
export const countryCodes = [
    { id: 1101, value: 'Australia', order: 1, type: 1101 },
    { id: 1102, value: 'Norfolk Island', order: 2, type: 1102 },
    { id: 1199, value: 'Australian External Territories, nec', order: 3, type: 1199 },
    { id: 1201, value: 'New Zealand', order: 4, type: 1201 },
    { id: 1301, value: 'New Caledonia', order: 5, type: 1301 },
    { id: 1302, value: 'Papua New Guinea', order: 6, type: 1302 },
    { id: 1303, value: 'Solomon Islands', order: 7, type: 1303 },
    { id: 1304, value: 'Vanuatu', order: 8, type: 1304 },
    { id: 1401, value: 'Guam', order: 9, type: 1401 },
    { id: 1402, value: 'Kiribati', order: 10, type: 1402 },
    { id: 1403, value: 'Marshall Islands', order: 11, type: 1403 },
    { id: 1404, value: 'Micronesia, Federated States of', order: 12, type: 1404 },
    { id: 1405, value: 'Nauru', order: 13, type: 1405 },
    { id: 1406, value: 'Northern Mariana Islands', order: 14, type: 1406 },
    { id: 1407, value: 'Palau', order: 15, type: 1407 },
    { id: 1501, value: 'Cook Islands', order: 16, type: 1501 },
    { id: 1502, value: 'Fiji', order: 17, type: 1502 },
    { id: 1503, value: 'French Polynesia', order: 18, type: 1503 },
    { id: 1504, value: 'Niue', order: 19, type: 1504 },
    { id: 1505, value: 'Samoa', order: 20, type: 1505 },
    { id: 1506, value: 'Samoa, American', order: 21, type: 1506 },
    { id: 1507, value: 'Tokelau', order: 22, type: 1507 },
    { id: 1508, value: 'Tonga', order: 23, type: 1508 },
    { id: 1511, value: 'Tuvalu', order: 24, type: 1511 },
    { id: 1512, value: 'Wallis and Futuna', order: 25, type: 1512 },
    { id: 1513, value: 'Pitcairn Islands', order: 26, type: 1513 },
    { id: 1599, value: 'Polynesia (excludes Hawaii), nec', order: 27, type: 1599 },
    { id: 1601, value: 'Adelie Land (France)', order: 28, type: 1601 },
    { id: 1602, value: 'Argentinian Antarctic Territory', order: 29, type: 1602 },
    { id: 1603, value: 'Australian Antarctic Territory', order: 30, type: 1603 },
    { id: 1604, value: 'British Antarctic Territory', order: 31, type: 1604 },
    { id: 1605, value: 'Chilean Antarctic Territory', order: 32, type: 1605 },
    { id: 1606, value: 'Queen Maud Land (Norway)', order: 33, type: 1606 },
    { id: 1607, value: 'Ross Dependency (New Zealand)', order: 34, type: 1607 },
    { id: 2102, value: 'England', order: 35, type: 2102 },
    { id: 2103, value: 'Isle of Man', order: 36, type: 2103 },
    { id: 2104, value: 'Northern Ireland', order: 37, type: 2104 },
    { id: 2105, value: 'Scotland', order: 38, type: 2105 },
    { id: 2106, value: 'Wales', order: 39, type: 2106 },
    { id: 2107, value: 'Guernsey', order: 40, type: 2107 },
    { id: 2108, value: 'Jersey', order: 41, type: 2108 },
    { id: 2201, value: 'Ireland', order: 42, type: 2201 },
    { id: 2301, value: 'Austria', order: 43, type: 2301 },
    { id: 2302, value: 'Belgium', order: 44, type: 2302 },
    { id: 2303, value: 'France', order: 45, type: 2303 },
    { id: 2304, value: 'Germany', order: 46, type: 2304 },
    { id: 2305, value: 'Liechtenstein', order: 47, type: 2305 },
    { id: 2306, value: 'Luxembourg', order: 48, type: 2306 },
    { id: 2307, value: 'Monaco', order: 49, type: 2307 },
    { id: 2308, value: 'Netherlands', order: 50, type: 2308 },
    { id: 2311, value: 'Switzerland', order: 51, type: 2311 },
    { id: 2401, value: 'Denmark', order: 52, type: 2401 },
    { id: 2402, value: 'Faroe Islands', order: 53, type: 2402 },
    { id: 2403, value: 'Finland', order: 54, type: 2403 },
    { id: 2404, value: 'Greenland', order: 55, type: 2404 },
    { id: 2405, value: 'Iceland', order: 56, type: 2405 },
    { id: 2406, value: 'Norway', order: 57, type: 2406 },
    { id: 2407, value: 'Sweden', order: 58, type: 2407 },
    { id: 2408, value: 'Aland Islands', order: 59, type: 2408 },
    { id: 3101, value: 'Andorra', order: 60, type: 3101 },
    { id: 3102, value: 'Gibraltar', order: 61, type: 3102 },
    { id: 3103, value: 'Holy See', order: 62, type: 3103 },
    { id: 3104, value: 'Italy', order: 63, type: 3104 },
    { id: 3105, value: 'Malta', order: 64, type: 3105 },
    { id: 3106, value: 'Portugal', order: 65, type: 3106 },
    { id: 3107, value: 'San Marino', order: 66, type: 3107 },
    { id: 3108, value: 'Spain', order: 67, type: 3108 },
    { id: 3201, value: 'Albania', order: 68, type: 3201 },
    { id: 3202, value: 'Bosnia and Herzegovina', order: 69, type: 3202 },
    { id: 3203, value: 'Bulgaria', order: 70, type: 3203 },
    { id: 3204, value: 'Croatia', order: 71, type: 3204 },
    { id: 3205, value: 'Cyprus', order: 72, type: 3205 },
    { id: 3206, value: 'North Macedonia', order: 73, type: 3206 },
    { id: 3207, value: 'Greece', order: 74, type: 3207 },
    { id: 3208, value: 'Moldova', order: 75, type: 3208 },
    { id: 3211, value: 'Romania', order: 76, type: 3211 },
    { id: 3212, value: 'Slovenia', order: 77, type: 3212 },
    { id: 3214, value: 'Montenegro', order: 78, type: 3214 },
    { id: 3215, value: 'Serbia', order: 79, type: 3215 },
    { id: 3216, value: 'Kosovo', order: 80, type: 3216 },
    { id: 3301, value: 'Belarus', order: 81, type: 3301 },
    { id: 3302, value: 'Czechia', order: 82, type: 3302 },
    { id: 3303, value: 'Estonia', order: 83, type: 3303 },
    { id: 3304, value: 'Hungary', order: 84, type: 3304 },
    { id: 3305, value: 'Latvia', order: 85, type: 3305 },
    { id: 3306, value: 'Lithuania', order: 86, type: 3306 },
    { id: 3307, value: 'Poland', order: 87, type: 3307 },
    { id: 3308, value: 'Russian Federation', order: 88, type: 3308 },
    { id: 3311, value: 'Slovakia', order: 89, type: 3311 },
    { id: 3312, value: 'Ukraine', order: 90, type: 3312 },
    { id: 4101, value: 'Algeria', order: 91, type: 4101 },
    { id: 4102, value: 'Egypt', order: 92, type: 4102 },
    { id: 4103, value: 'Libya', order: 93, type: 4103 },
    { id: 4104, value: 'Morocco', order: 94, type: 4104 },
    { id: 4105, value: 'Sudan', order: 95, type: 4105 },
    { id: 4106, value: 'Tunisia', order: 96, type: 4106 },
    { id: 4107, value: 'Western Sahara', order: 97, type: 4107 },
    { id: 4108, value: 'Spanish North Africa', order: 98, type: 4108 },
    { id: 4111, value: 'South Sudan', order: 99, type: 4111 },
    { id: 4201, value: 'Bahrain', order: 100, type: 4201 },
    { id: 4202, value: 'Gaza Strip and West Bank', order: 101, type: 4202 },
    { id: 4203, value: 'Iran', order: 102, type: 4203 },
    { id: 4204, value: 'Iraq', order: 103, type: 4204 },
    { id: 4205, value: 'Israel', order: 104, type: 4205 },
    { id: 4206, value: 'Jordan', order: 105, type: 4206 },
    { id: 4207, value: 'Kuwait', order: 106, type: 4207 },
    { id: 4208, value: 'Lebanon', order: 107, type: 4208 },
    { id: 4211, value: 'Oman', order: 108, type: 4211 },
    { id: 4212, value: 'Qatar', order: 109, type: 4212 },
    { id: 4213, value: 'Saudi Arabia', order: 110, type: 4213 },
    { id: 4214, value: 'Syria', order: 111, type: 4214 },
    { id: 4215, value: 'Turkey', order: 112, type: 4215 },
    { id: 4216, value: 'United Arab Emirates', order: 113, type: 4216 },
    { id: 4217, value: 'Yemen', order: 114, type: 4217 },
    { id: 5101, value: 'Myanmar', order: 115, type: 5101 },
    { id: 5102, value: 'Cambodia', order: 116, type: 5102 },
    { id: 5103, value: 'Laos', order: 117, type: 5103 },
    { id: 5104, value: 'Thailand', order: 118, type: 5104 },
    { id: 5105, value: 'Vietnam', order: 119, type: 5105 },
    { id: 5201, value: 'Brunei Darussalam', order: 120, type: 5201 },
    { id: 5202, value: 'Indonesia', order: 121, type: 5202 },
    { id: 5203, value: 'Malaysia', order: 122, type: 5203 },
    { id: 5204, value: 'Philippines', order: 123, type: 5204 },
    { id: 5205, value: 'Singapore', order: 124, type: 5205 },
    { id: 5206, value: 'Timor-Leste', order: 125, type: 5206 },
    { id: 6101, value: 'China (excludes SARs and Taiwan) ', order: 126, type: 6101 },
    { id: 6102, value: 'Hong Kong (SAR of China)', order: 127, type: 6102 },
    { id: 6103, value: 'Macau (SAR of China)', order: 128, type: 6103 },
    { id: 6104, value: 'Mongolia', order: 129, type: 6104 },
    { id: 6105, value: 'Taiwan ', order: 130, type: 6105 },
    { id: 6201, value: 'Japan', order: 131, type: 6201 },
    { id: 6202, value: 'Korea, Democratic People\'s Republic of(North)', order: 132, type: 6202 },
    { id: 6203, value: 'Korea, Republic of (South)', order: 133, type: 6203 },
    { id: 7101, value: 'Bangladesh', order: 134, type: 7101 },
    { id: 7102, value: 'Bhutan', order: 135, type: 7102 },
    { id: 7103, value: 'India', order: 136, type: 7103 },
    { id: 7104, value: 'Maldives', order: 137, type: 7104 },
    { id: 7105, value: 'Nepal', order: 138, type: 7105 },
    { id: 7106, value: 'Pakistan', order: 139, type: 7106 },
    { id: 7107, value: 'Sri Lanka', order: 140, type: 7107 },
    { id: 7201, value: 'Afghanistan', order: 141, type: 7201 },
    { id: 7202, value: 'Armenia', order: 142, type: 7202 },
    { id: 7203, value: 'Azerbaijan', order: 143, type: 7203 },
    { id: 7204, value: 'Georgia', order: 144, type: 7204 },
    { id: 7205, value: 'Kazakhstan', order: 145, type: 7205 },
    { id: 7206, value: 'Kyrgyzstan', order: 146, type: 7206 },
    { id: 7207, value: 'Tajikistan', order: 147, type: 7207 },
    { id: 7208, value: 'Turkmenistan', order: 148, type: 7208 },
    { id: 7211, value: 'Uzbekistan', order: 149, type: 7211 },
    { id: 8101, value: 'Bermuda', order: 150, type: 8101 },
    { id: 8102, value: 'Canada', order: 151, type: 8102 },
    { id: 8103, value: 'St Pierre and Miquelon', order: 152, type: 8103 },
    { id: 8104, value: 'United States of America', order: 153, type: 8104 },
    { id: 8201, value: 'Argentina', order: 154, type: 8201 },
    { id: 8202, value: 'Bolivia', order: 155, type: 8202 },
    { id: 8203, value: 'Brazil', order: 156, type: 8203 },
    { id: 8204, value: 'Chile', order: 157, type: 8204 },
    { id: 8205, value: 'Colombia', order: 158, type: 8205 },
    { id: 8206, value: 'Ecuador', order: 159, type: 8206 },
    { id: 8207, value: 'Falkland Islands', order: 160, type: 8207 },
    { id: 8208, value: 'French Guiana', order: 161, type: 8208 },
    { id: 8211, value: 'Guyana', order: 162, type: 8211 },
    { id: 8212, value: 'Paraguay', order: 163, type: 8212 },
    { id: 8213, value: 'Peru', order: 164, type: 8213 },
    { id: 8214, value: 'Suriname', order: 165, type: 8214 },
    { id: 8215, value: 'Uruguay', order: 166, type: 8215 },
    { id: 8216, value: 'Venezuela', order: 167, type: 8216 },
    { id: 8299, value: 'South America, nec', order: 168, type: 8299 },
    { id: 8301, value: 'Belize', order: 169, type: 8301 },
    { id: 8302, value: 'Costa Rica', order: 170, type: 8302 },
    { id: 8303, value: 'El Salvador', order: 171, type: 8303 },
    { id: 8304, value: 'Guatemala', order: 172, type: 8304 },
    { id: 8305, value: 'Honduras', order: 173, type: 8305 },
    { id: 8306, value: 'Mexico', order: 174, type: 8306 },
    { id: 8307, value: 'Nicaragua', order: 175, type: 8307 },
    { id: 8308, value: 'Panama', order: 176, type: 8308 },
    { id: 8401, value: 'Anguilla', order: 177, type: 8401 },
    { id: 8402, value: 'Antigua and Barbuda', order: 178, type: 8402 },
    { id: 8403, value: 'Aruba', order: 179, type: 8403 },
    { id: 8404, value: 'Bahamas', order: 180, type: 8404 },
    { id: 8405, value: 'Barbados', order: 181, type: 8405 },
    { id: 8406, value: 'Cayman Islands', order: 182, type: 8406 },
    { id: 8407, value: 'Cuba', order: 183, type: 8407 },
    { id: 8408, value: 'Dominica', order: 184, type: 8408 },
    { id: 8411, value: 'Dominican Republic', order: 185, type: 8411 },
    { id: 8412, value: 'Grenada', order: 186, type: 8412 },
    { id: 8413, value: 'Guadeloupe', order: 187, type: 8413 },
    { id: 8414, value: 'Haiti', order: 188, type: 8414 },
    { id: 8415, value: 'Jamaica', order: 189, type: 8415 },
    { id: 8416, value: 'Martinique', order: 190, type: 8416 },
    { id: 8417, value: 'Montserrat', order: 191, type: 8417 },
    { id: 8421, value: 'Puerto Rico', order: 192, type: 8421 },
    { id: 8422, value: 'St Kitts and Nevis', order: 193, type: 8422 },
    { id: 8423, value: 'St Lucia', order: 194, type: 8423 },
    { id: 8424, value: 'St Vincent and the Grenadines', order: 195, type: 8424 },
    { id: 8425, value: 'Trinidad and Tobago', order: 196, type: 8425 },
    { id: 8426, value: 'Turks and Caicos Islands', order: 197, type: 8426 },
    { id: 8427, value: 'Virgin Islands, British ', order: 198, type: 8427 },
    { id: 8428, value: 'Virgin Islands, United States', order: 199, type: 8428 },
    { id: 8431, value: 'St Barthelemy', order: 200, type: 8431 },
    { id: 8432, value: 'St Martin (French part)', order: 201, type: 8432 },
    { id: 8433, value: 'Bonaire, Sint Eustatius and Saba', order: 202, type: 8433 },
    { id: 8434, value: 'Curacao', order: 203, type: 8434 },
    { id: 8435, value: 'Sint Maarten (Dutch part)', order: 204, type: 8435 },
    { id: 9101, value: 'Benin', order: 205, type: 9101 },
    { id: 9102, value: 'Burkina Faso', order: 206, type: 9102 },
    { id: 9103, value: 'Cameroon', order: 207, type: 9103 },
    { id: 9104, value: 'Cabo Verde', order: 208, type: 9104 },
    { id: 9105, value: 'Central African Republic', order: 209, type: 9105 },
    { id: 9106, value: 'Chad', order: 210, type: 9106 },
    { id: 9107, value: 'Congo, Republic of', order: 211, type: 9107 },
    { id: 9108, value: 'Congo, Democratic Republic of', order: 212, type: 9108 },
    { id: 9111, value: 'Cote d\'Ivoire', order: 213, type: 9111 },
    { id: 9112, value: 'Equatorial Guinea', order: 214, type: 9112 },
    { id: 9113, value: 'Gabon', order: 215, type: 9113 },
    { id: 9114, value: 'Gambia', order: 216, type: 9114 },
    { id: 9115, value: 'Ghana', order: 217, type: 9115 },
    { id: 9116, value: 'Guinea', order: 218, type: 9116 },
    { id: 9117, value: 'Guinea-Bissau', order: 219, type: 9117 },
    { id: 9118, value: 'Liberia', order: 220, type: 9118 },
    { id: 9121, value: 'Mali', order: 221, type: 9121 },
    { id: 9122, value: 'Mauritania', order: 222, type: 9122 },
    { id: 9123, value: 'Niger', order: 223, type: 9123 },
    { id: 9124, value: 'Nigeria', order: 224, type: 9124 },
    { id: 9125, value: 'Sao Tome and Principe', order: 225, type: 9125 },
    { id: 9126, value: 'Senegal', order: 226, type: 9126 },
    { id: 9127, value: 'Sierra Leone', order: 227, type: 9127 },
    { id: 9128, value: 'Togo', order: 228, type: 9128 },
    { id: 9201, value: 'Angola', order: 229, type: 9201 },
    { id: 9202, value: 'Botswana', order: 230, type: 9202 },
    { id: 9203, value: 'Burundi', order: 231, type: 9203 },
    { id: 9204, value: 'Comoros', order: 232, type: 9204 },
    { id: 9205, value: 'Djibouti', order: 233, type: 9205 },
    { id: 9206, value: 'Eritrea', order: 234, type: 9206 },
    { id: 9207, value: 'Ethiopia', order: 235, type: 9207 },
    { id: 9208, value: 'Kenya', order: 236, type: 9208 },
    { id: 9211, value: 'Lesotho', order: 237, type: 9211 },
    { id: 9212, value: 'Madagascar', order: 238, type: 9212 },
    { id: 9213, value: 'Malawi', order: 239, type: 9213 },
    { id: 9214, value: 'Mauritius', order: 240, type: 9214 },
    { id: 9215, value: 'Mayotte', order: 241, type: 9215 },
    { id: 9216, value: 'Mozambique', order: 242, type: 9216 },
    { id: 9217, value: 'Namibia', order: 243, type: 9217 },
    { id: 9218, value: 'Reunion', order: 244, type: 9218 },
    { id: 9221, value: 'Rwanda', order: 245, type: 9221 },
    { id: 9222, value: 'St Helena', order: 246, type: 9222 },
    { id: 9223, value: 'Seychelles', order: 247, type: 9223 },
    { id: 9224, value: 'Somalia', order: 248, type: 9224 },
    { id: 9225, value: 'South Africa', order: 249, type: 9225 },
    { id: 9226, value: 'Eswatini', order: 250, type: 9226 },
    { id: 9227, value: 'Tanzania', order: 251, type: 9227 },
    { id: 9228, value: 'Uganda', order: 252, type: 9228 },
    { id: 9231, value: 'Zambia', order: 253, type: 9231 },
    { id: 9232, value: 'Zimbabwe', order: 254, type: 9232 },
    { id: 9299, value: 'Southern and East Africa, nec', order: 255, type: 9299 },

];