import 'core-js';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import { enableES5 } from 'immer';

import { OnlinePatientManagementProvider } from '@ngt/opms';
import onlinePatientManagement from './onlinePatientManagement';
import { Switch, Route } from 'react-router-dom';
import App from './App';

import { Settings as LuxonSettings } from 'luxon';

enableES5();
declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

//const eConsentSettings = global.ECONSENT_SETTINGS as Dtos.GetEConsentSettingsResponse;
//const publicRoute = [eConsentSettings.trialInfoPageUrl, eConsentSettings.trialConsentPageUrl].includes(global.location.href.substr(0, global.location.href.lastIndexOf("/") + 1));
const publicRoute = false;

//set default timezone to australia/sydney so dates at midnight appear as the correct day for users in other timezones in the world
LuxonSettings.defaultZoneName = 'Australia/Sydney';

if (!!onlinePatientManagement.store.getState().authenticatedUser.user || publicRoute) {
    ReactDOM.render(
        <OnlinePatientManagementProvider value={onlinePatientManagement}>
            <Switch>
                <Route path="/">
                    <App />
                </Route>
            </Switch>
        </OnlinePatientManagementProvider>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
