/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae notify page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';
import { SaeBreadcrumbs } from '@ngt/opms-sae';
import { RequestState } from '@ngt/request-utilities';
import { usePermissionsByIds } from '@ngt/opms-trogapi';
import { InstitutionContext } from '@ngt/opms';
import { SaeNotificationForm } from '@ngt/opms-sae';
import { useHistory, useParams } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos'

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaeNotifyPageParams {
    patientStudyNumber?: string,
    saeNumber?: string;
    notifyMode: string
}

interface ISaeNotifyPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.SaeReview,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.MedicalReviewPerform
];

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */

const SaeNotifyPage: React.FunctionComponent<ISaeNotifyPageProps> = () => {
    const history = useHistory();

    const params = useParams<ISaeNotifyPageParams>();

    const contextInstitution = React.useContext(InstitutionContext);

    const [[/*canViewSae*/, /*canReviewSae*/, canAdministerSae, /*canMedicallyReviewSae*/], saePermissionLoadState] = usePermissionsByIds(permissions, null, null, contextInstitution?.institution?.id, null, contextInstitution?.institution ? true : false);

    React.useEffect(() => {
        if (saePermissionLoadState.state !== RequestState.Pending &&
            saePermissionLoadState.state !== RequestState.None) {
            if (!canAdministerSae) {
                history.replace(`/error/403`);
            }
        }
    }, [canAdministerSae, saePermissionLoadState, history]);

    return (
        <>
            <SaeBreadcrumbs />

            <SaeNotificationForm notifyMode={params?.notifyMode} />  
        </>    
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaeNotifyPage;