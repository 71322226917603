/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ICancerDetailsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const confirmedHCCyes = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.confirmedSingleNoduleHCC === Dtos.LookupYesNoType.Yes;
const suitableForLiverResectionYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.liverResectionSuitable === Dtos.LookupYesNoType.Yes;
const suitableForMwaRfaNo = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.suitableForMwaRfa === Dtos.LookupYesNoType.No;
const hepStatus = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.hepCstatus === Dtos.LookupYesNoType.Yes || formState?.values.hepBstatus === Dtos.LookupYesNoType.Yes;
const notProtocolVersion1 = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.protocolVersion !== (window as any).PROTOCOL_VERSION_1_ID;
const hasPreviousDiagnosisOfHcc = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.previousDiagnosisOfHcc === Dtos.LookupYesNoType.Yes;
const previousHccSegmentOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.previousHccSegment === Dtos.LookupSegmentType.Other;
const previousHccTreated = ({ lookups, formState }: ICrfConditionParameters<Dtos.CancerDetails>) => formState?.values.previousHccTreated === Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const CancerDetails: React.FunctionComponent<ICancerDetailsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.CancerDetails}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <FieldGroup>
                    <Field
                        name="confirmedSingleNoduleHCC"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={confirmedHCCyes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="methodOfHccNoduleDiagnosis"
                            component={Select}
                        />
                        <Field
                            name="dateOfConfirmedHCC"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                </FieldGroup>
                <CrfCondition
                    type={Dtos.CancerDetails}
                    condition={notProtocolVersion1}
                    mode="Show"
                    subscription={{ values: true }}
                >
                    <FieldGroup>
                        <Field
                            name="previousDiagnosisOfHcc"
                            component={ToggleButtonGroup}
                        />
                        <CrfCondition
                            type={Dtos.CancerDetails}
                            condition={hasPreviousDiagnosisOfHcc}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="earlyStageSolitaryHcc"
                                component={ToggleButtonGroup}
                            />
                            <Field
                                name="previousHccSegment"
                                component={Select}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.CancerDetails}
                            condition={previousHccSegmentOther}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="previousHccSegmentOther"
                                component={Text}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.CancerDetails}
                            condition={hasPreviousDiagnosisOfHcc}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="previousHccTreated"
                                component={ToggleButtonGroup}
                            />
                        </CrfCondition>
                        <CrfCondition
                            type={Dtos.CancerDetails}
                            condition={previousHccTreated}
                            mode="Show"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="evidenceOfResidualDisease"
                                component={ToggleButtonGroup}
                            />
                        </CrfCondition>
                    </FieldGroup>
                </CrfCondition>
                <Field
                    name="pughClass"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="pughPoints"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="bclcStage"
                    component={Select}
                />
                <Field
                    name="liverResectionSuitable"
                    component={ToggleButtonGroup}
                />
                <CrfCondition
                    type={Dtos.CancerDetails}
                    mode="Enable"
                    condition={suitableForLiverResectionYes}
                    subscription={{ values: true }}
                >
                    <Field
                        name="liverResectionDeclined"
                        component={ToggleButtonGroup}
                    />
                </CrfCondition>
                <Field
                    name="liverTransplant"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Field
                        name="suitableForTreatment"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="mdtDate"
                        component={KeyboardDatePicker}
                    />
                    <Field
                        name="hccSize"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="suitableForMwaRfa"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        condition={suitableForMwaRfaNo}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="sizeMoreThanThree"
                            label={'Reason/s lesion not suitable for thermal ablation (tick all that apply)'}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                        <Field
                            name="subcapsular"
                            label={' '}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                        <Field
                            name="proximityToBowel"
                            label={' '}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                        <Field
                            name="proximityToCentralBile"
                            label={' '}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                        <Field
                            name="proximityToBloodVessels"
                            label={' '}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                        <Field
                            name="subphrenic"
                            label={' '}
                            sm={5}
                            md={5}
                            xl={5}
                            lg={5}
                            component={Checkbox}
                        />
                    </CrfCondition>
                    <Field
                        name="suitableForTaceTare"
                        component={ToggleButtonGroup}
                    />
                </FieldGroup>
                
                <FieldGroup>
                    <Field
                        name="hepBstatus"
                        component={ToggleButtonGroup}
                    />
                    <Field
                        name="hepCstatus"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.CancerDetails}
                        mode="Enable"
                        condition={hepStatus}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="hepTreatment"
                            component={ToggleButtonGroup}
                        />
                    </CrfCondition>
                </FieldGroup>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default CancerDetails;
