/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    FormGrid,
    useFormState,
    IFormGridCell,
    Input,
    CtcaeSelector,
    Masked,
    getParentPropertyPath,
    useFormActions
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { get } from 'lodash-es';
import { THREE_DIGIT_POSITIVE_INTEGER } from '../../utils/numberFormat';
import { FOUR_DIGIT_POSITIVE_INTEGER } from './Bloods';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IClinicalAssessmentProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const anyRelevantMedicalHistoryYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => GetFieldLookupItem(lookups, 'anyImportantHistory', formState?.values.anyImportantHistory)?.type === Dtos.LookupYesNoType.Yes;

const isMedHistoryNotOngoing = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.MedicalHistory = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.ongoing !== true;
};

export const UK_DATE = {
    mask: [/[0-3Uu]/, /[0-9Kk]/, '/', /[0-1Uu]/, /[0-9Kk]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    showMask: true
};

export const THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

export const TWO_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE = {
    decimalScale: 1,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 100) {
            return true;
        }

        return false;
    }
}

const medicalHistoryColumns: Array<IFormGridCell<Dtos.MedicalHistory>> = [
    {
        name: 'category',
        minWidth: 200,
        maxWidth: 200,
        width: 100,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'condition',
        minWidth: 280,
        maxWidth: 280,
        width: 140,
        content: (
            <Input
                component={Text}
            />
        )
    },
    {
        name: 'startDate',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <Input
                component={Masked}
                maskedFormat={UK_DATE}
            />
        )
    },
    {
        name: 'stopDate',
        minWidth: 150,
        maxWidth: 150,
        width: 150,
        content: (
            <CrfCondition
                type={Dtos.ClinicalAssessment}
                condition={isMedHistoryNotOngoing}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Masked}
                    maskedFormat={UK_DATE}
                />
            </CrfCondition>
        )
    },
    {
        name: 'ongoing',
        header: (<></>),
        minWidth: 120,
        maxWidth: 120,
        width: 120,
        content: (
            <Input
                component={Checkbox}
            />
        )
    }
]; 

const participantIsNotMale = ({ lookups, formState }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => GetFieldLookupItem(lookups, 'sex', formState?.values.sex)?.type !== Dtos.LookupSexType.Male;
const fertilityStatusPeriOrPreMenopausal = ({ lookups, formState }: ICrfConditionParameters<Dtos.ClinicalAssessment>) => GetFieldLookupItem(lookups, 'fertilityStatus', formState?.values.fertilityStatus)?.type === Dtos.LookupFertilityStatusType.PeriOrPremenopausal;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const MedicalHistoryTbl: React.FunctionComponent = () => {
    const classes = useStyles();

    const { values: formValues } = useFormState<Dtos.ClinicalAssessment, any>({ values: true });

    const { setFieldValue } = useFormActions();

    React.useEffect(() => {
        if (formValues.weight != null && formValues.height != null) {
            const computedBmi = formValues.weight / ((formValues.height / 100) ** 2)

            setFieldValue(`bmi`, computedBmi);
        } else {
            setFieldValue(`bmi`, null);
        }
    }, [formValues, setFieldValue]);

    return (
        <>
            <CrfCondition
                type={Dtos.ClinicalAssessment}
                condition={anyRelevantMedicalHistoryYes}
                mode="Show"
                subscription={{ values: true }}
            >
                <Typography
                    variant="h3"
                    color="secondary"
                    className={classes.subHeading}
                >
                    Relevant Medical History
                </Typography>
                <FormGrid
                    type={Dtos.MedicalHistory}
                    name="medicalHistory"
                    columns={medicalHistoryColumns}
                    minRow={formValues.anyImportantHistory ? 1 : undefined}
                />
            </CrfCondition>
        </>
    );
}

const ClinicalAssessment: React.FunctionComponent<IClinicalAssessmentProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ClinicalAssessment}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="dateOfClinicalAssessment"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="height"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                cm&nbsp;
                            </InputAdornment>
                        )
                    }}
                /> 
                <Field
                    name="weight"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                kg&nbsp;
                            </InputAdornment>
                        )
                    }}
                /> 
                <Field
                    name="bmi"
                    component={Numeric}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER_ONE_DECIMAL_PLACE}
                /> 
                <Field
                    name="ecogPerformanceStatus"
                    component={Select}
                />
                <Field
                    name="ascites"
                    component={Select}
                />
                <Field
                    name="hepaticEncephalopathy"
                    component={Select}
                />

                <Field
                    name="anyImportantHistory"
                    component={ToggleButtonGroup}
                />
                <MedicalHistoryTbl />
                <CrfCondition
                    type={Dtos.ClinicalAssessment}
                    mode="Show"
                    condition={participantIsNotMale}
                    subscription={{ values: true }}
                >
                    <FieldGroup>
                        <Field
                            name="fertilityStatus"
                            component={Select}
                        />
                    
                        <CrfCondition
                            type={Dtos.ClinicalAssessment}
                            mode="Enable"
                            condition={fertilityStatusPeriOrPreMenopausal}
                            subscription={{ values: true }}
                        >
                            <Field
                                name="dateOfPregnancyTest"
                                component={KeyboardDatePicker}
                            />
                            <Field
                                name="pregnancyTestResult"
                                component={Select}
                            />
                        </CrfCondition>
                    </FieldGroup>
                </CrfCondition>
                <Field
                    name="travelTime"
                    label={<Typography>What was the patient's travel time to clinic <i>(Home to clinic in minutes)</i></Typography>}
                    component={Numeric}
                    numberFormat={FOUR_DIGIT_POSITIVE_INTEGER}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                min&nbsp;
                            </InputAdornment>
                        )
                    }}
                />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ClinicalAssessment;
