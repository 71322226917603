/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use Ineligible patient columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

/*
 * Used to type patients and institutions 
 */
import {
    IInstitution
} from '@ngt/opms';

import { Chip } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useIneligiblePatientColumns = (institutions: IInstitution[] | null, showSite: boolean): Column<Dtos.Patient>[] => {
    const columns = React.useMemo(() => {
        const c: (Column<Dtos.Patient> | boolean)[] = [
            {
                field: 'studyNumber',
                title: 'Study Number',
                width: 155
            },
            showSite && {
                field: 'institutionCode',
                title: 'Site Code',
                render: rowData => (
                    <Chip
                        label={rowData.institutionCode}
                        color="secondary"
                        size="small"
                    />
                ),
                width: 125
            },
            showSite && {
                field: 'institutionId',
                title: 'Institution',
                render: rowData => (
                    institutions?.find(i => i.id === rowData.institutionId)?.name
                )
            },
            {
                field: 'patientStateComment',
                title: 'Ineligibility Reason',
                width: 210
            },
        ];

        return c.filter(column => !!column) as Column<Dtos.Patient>[];
    }, [showSite, institutions]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useIneligiblePatientColumns;