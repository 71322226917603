
 //* ---------------------------------------------------------------------------------
 //* Copyright:
 //*      NewtonGreen Technologies Pty. Ltd.
 //*      Level 4, 175 Scott St.
 //*      Newcastle, NSW, 2300
 //*      Australia
 //*
 //*      E-mail: support@newtongreen.com
 //*      Tel: (02) 4925 5288
 //*      Fax: (02) 4925 3068
 //*
 //*      All Rights Reserved.
 //* ---------------------------------------------------------------------------------
 


 //* --------------------------------------------------------------------------------
 //* This file contains the hook to use screening summary columns.
 //* --------------------------------------------------------------------------------
 


 //* ---------------------------------------------------------------------------------
 //* Imports - External
 //* ---------------------------------------------------------------------------------
 


 //* Used to create a context.
 
import * as React from 'react';


 //* Used to type columns
 
import { Column } from 'material-table';


 //* Used to format datetimes
 
import { DateTime } from 'luxon';

 //* ---------------------------------------------------------------------------------
 //* Imports - Internal
 //* ---------------------------------------------------------------------------------
 

import * as Dtos from '../../api/dtos';


 //* ---------------------------------------------------------------------------------
 //* Functions
 //* ---------------------------------------------------------------------------------
 

const useScreeningSummaryColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean, ineligible: boolean, unsuitable: boolean, declined: boolean): Array<Column<Dtos.ScreeningSummary>> => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.ScreeningSummary> | boolean> = [
            {
                field: ("summaryDate"),
                title: ("Start date"),
                render: (screeningSummary, type) => (screeningSummary.summaryDate ? DateTime.fromISO(screeningSummary.summaryDate).toFormat('dd/MM/yyyy') : ''),
            },
            {
                field: ("endDate"),
                title: ("End date"),
                render: (screeningSummary, type) => (screeningSummary.endDate ? DateTime.fromISO(screeningSummary.endDate).toFormat('dd/MM/yyyy') : ''),
            },
            {
                field: ("preScreened"),
                title: ("Number of patients Pre-Screened"),
                render: (screeningSummary, type) => (screeningSummary.preScreened ? screeningSummary.preScreened : 0),
            },
            {
                field: ("numberConsented"),
                title: ("Number of patients Consented"),
                render: (screeningSummary, type) => (screeningSummary.numberConsented ? screeningSummary.numberConsented : 0),
            }
        ];
        if (ineligible) {
            cols.push(
                {
                    field: ("multiFocalHcc"),
                    title: ("Multifocal HCC, macrovascular invasion and/or extrahepatic disease"),
                    render: (screeningSummary, type) => (screeningSummary.multiFocalHcc ? screeningSummary.multiFocalHcc : 0),
                },
                {
                    field: ("priorTreatmentForHcc"),
                    title: ("Patient has had prior treatment for HCC within the last 2 years "),
                    render: (screeningSummary, type) => (screeningSummary.priorTreatmentForHcc ? screeningSummary.priorTreatmentForHcc : 0),
                },
                {
                    field: ("hccGreaterThan8Cm"),
                    title: ("HCC >8cm"),
                    render: (screeningSummary, type) => (screeningSummary.hccGreaterThan8Cm ? screeningSummary.hccGreaterThan8Cm : 0),
                },
                {
                    field: ("childPughGreaterThanB7"),
                    title: ("Child Pugh >B7"),
                    render: (screeningSummary, type) => (screeningSummary.childPughGreaterThanB7 ? screeningSummary.childPughGreaterThanB7 : 0),
                },
                {
                    field: ("ecogPerformanceStatusGreaterThan2"),
                    title: ("ECOG performance status >2"),
                    render: (screeningSummary, type) => (screeningSummary.ecogPerformanceStatusGreaterThan2 ? screeningSummary.ecogPerformanceStatusGreaterThan2 : 0),
                },
                {
                    field: ("suitableForLiverResection"),
                    title: ("Suitable for liver resection (and not declined)"),
                    render: (screeningSummary, type) => (screeningSummary.suitableForLiverResection ? screeningSummary.suitableForLiverResection : 0),
                },
                {
                    field: ("notSuitableForStudyTx"),
                    title: ("Patient not suitable for study treatments"),
                    render: (screeningSummary, type) => (screeningSummary.notSuitableForStudyTx ? screeningSummary.notSuitableForStudyTx : 0),
                },
                {
                    field: ("plannedForLiverTransplant"),
                    title: ("Planned for liver transplantation"),
                    render: (screeningSummary, type) => (screeningSummary.plannedForLiverTransplant ? screeningSummary.plannedForLiverTransplant : 0),
                },
                {
                    field: ("inadequateOrganAndMarrowFx"),
                    title: ("Inadequate organ and marrow function"),
                    render: (screeningSummary, type) => (screeningSummary.inadequateOrganAndMarrowFx ? screeningSummary.inadequateOrganAndMarrowFx : 0),
                },
                {
                    field: ("clinicallyEvidentAscites"),
                    title: ("Clinically evident ascites or hepatic encephalopathy"),
                    render: (screeningSummary, type) => (screeningSummary.clinicallyEvidentAscites ? screeningSummary.clinicallyEvidentAscites : 0),
                },
                {
                    field: ("knownOrPriorInvasiveMalignancy"),
                    title: ("Known or prior invasive malignancy excluding them from trial participation"),
                    render: (screeningSummary, type) => (screeningSummary.knownOrPriorInvasiveMalignancy ? screeningSummary.knownOrPriorInvasiveMalignancy : 0),
                },
                {
                    field: ("otherEligibility"),
                    title: ("Other eligibility criteria not met "),
                    render: (screeningSummary, type) => (screeningSummary.otherEligibility ? screeningSummary.otherEligibility : 0),
                }
            );
        }
        if (unsuitable) {
            cols.push(
                {
                    field: ("incompatibleComorbidities"),
                    title: ("Other incompatible comorbidities"),
                    render: (screeningSummary, type) => (screeningSummary.incompatibleComorbidities ? screeningSummary.incompatibleComorbidities : 0),
                },
                {
                    field: ("unableToComply"),
                    title: ("Unable to comply with protocol"),
                    render: (screeningSummary, type) => (screeningSummary.unableToComply ? screeningSummary.unableToComply : 0),
                },
                {
                    field: ("siteRestrictions"),
                    title: ("Site Restrictions"),
                    render: (screeningSummary, type) => (screeningSummary.siteRestrictions ? screeningSummary.siteRestrictions : 0),
                }
            );
        }
        if (declined) {
            cols.push(
                {
                    field: ("declined"),
                        title: ("Did not want to participate"),
                            render: (screeningSummary, type) => (screeningSummary.declined ? screeningSummary.declined : 0),
                }
            );
        }
        return cols.filter(column => !!column) as Array<Column<Dtos.ScreeningSummary>>;
    }, [institutions, showSite, ineligible, unsuitable, declined]);

    return columns;
};

 //* ---------------------------------------------------------------------------------
 //* Default Export
 //* ---------------------------------------------------------------------------------
 

export default useScreeningSummaryColumns;