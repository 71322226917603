/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    InstitutionsContext,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    //useAuthenticatedUser,
    ICrfFormMapping
    //UserType,
    //InstitutionsByIdsResolver
} from '@ngt/opms';

import { SaeByPatientStudyNumberRoute } from '@ngt/opms-sae'

import { usePermissionsByIds } from '@ngt/opms-trogapi'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography } from '@material-ui/core';

/**
 * Used for the ecrf icon.
 */
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the ecrf icon.
 */
//import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
//import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

//import { faFirstAid } from '@fortawesome/pro-duotone-svg-icons/faFirstAid';

import { Switch, Redirect, Route } from 'react-router-dom';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

import { faFileChartColumn } from '@fortawesome/pro-duotone-svg-icons';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

import General from './containers/form/General';

import CancerDetails from './containers/form/CancerDetails';

import ClinicalAssessment from './containers/form/ClinicalAssessment';

import ConcomitantMedication from './containers/form/ConcomitantMedication';

import DiseaseAssessment from './containers/form/DiseaseAssessment';

import DemographicDetails from './containers/form/DemographicDetails';

import Bloods from './containers/form/Bloods';

import EligibilityConfirmation from './containers/form/EligibilityConfirmation';

import SourceDocuments from './containers/form/SourceDocuments';

import * as Dtos from './api/dtos';

import SaesPage from './containers/sae/SaesPage';

import SaeViewPage from './containers/sae/SaeViewPage';

import SaeNotifyPage from './containers/sae/SaeNotifyPage';

import ProForm from './containers/form/ProForm';

import ScreeningSummaryPage from './containers/screeninglog/ScreeningSummaryPage';

import ReportsPage from './containers/reports/ReportsPage';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const formMapping: Record<string, ICrfFormMapping> = {
    "general": {
        components: <General />
    },
    "cancer-details": {
        components: <CancerDetails />
    },
    "clinical-assessments": {
        components: <ClinicalAssessment />
    },
    "concomitant-medication-form": {
        components: <ConcomitantMedication />
    },
    "disease-assessment": {
        components: <DiseaseAssessment />
    },
    "demographic-details": {
        components: <DemographicDetails />
    },
    "bloods": {
        components: <Bloods />
    },
    "eligibility-confirmation": {
        components: <EligibilityConfirmation />
    },
    "source-documents": {
        components: <SourceDocuments />
    },
    "pro-form": {
        components: <ProForm />
    }
}

const ecrfSettings = global.ECRF_SETTINGS as Dtos.GetEcrfSettingsResponse;

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.SaeReview,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.MedicalReviewPerform,
    Dtos.Permission.ScreeningLogView,
    Dtos.Permission.ScreeningLogUpdate
];


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const [[canViewSae, , , , canViewScreeningLog, , canViewEConsent, ,], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {
        let items: IMenuItem[] = [];

        //if (canViewEConsent) {
        //    items.push({
        //        url: '/econsent',
        //        label: 'eConsent',
        //        external: false,
        //        newTab: false,
        //        icon: eConsentIcon
        //    });
        //}

        if (canViewScreeningLog) {
            items.push({
                url: '/screeningsummary',
                label: 'Pre-screening Summary Log',
                external: false,
                newTab: false,
                icon: screeningLogIcon
            });
        }

        items.push(
            {
                url: '/registration',
                label: 'Participants',
                external: false,
                newTab: false,
                icon: faUser as any
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Summary',
                external: false,
                newTab: false,
                icon: saeIcon as any
            });
        }

        items.push(
            {
                url: ecrfSettings.ecrfUrl,
                label: 'eCRF',
                external: true,
                newTab: true,
                icon: faList
            }
        );

        if (canViewScreeningLog) {
            items.push({
                url: '/reports',
                label: 'Reports',
                external: false,
                newTab: false,
                icon: faFileChartColumn
            });
        }

        return items;
    }, [canViewEConsent, canViewSae, canViewScreeningLog]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const isMobile = useIsMobile();

    const { institutions } = React.useContext(InstitutionsContext);

    const loadingPermissions = permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending;

    const noAccess: boolean = React.useMemo(() => {
        if (canViewSae ||
            loadingPermissions) {
            return false;
        }
        if (institutions && institutions.length > 0) {
            return false;
        }
        return true;
    }, [canViewSae, loadingPermissions, institutions]);


    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    if (noAccess) {
        return <OnlinePatientManagementLayout
            menuItems={[]}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2021 Trans Tasman Radiation Oncology Group (TROG).
                    </Typography>
            }>
            <div className="container bg-white py-3 box-shadow-x">
                <div className="row">
                    <div className="col" style={{ textAlign: 'center', padding: '1em' }}>
                        <h1 className="text-danger p-3" style={{ color: 'red' }}>
                            <FontAwesomeIcon icon={faUser} fixedWidth />&nbsp;No Access</h1>
                        <h3 className="p-3">This user account has not been assigned any roles for this website.</h3>
                        <h5 className="p-3">
                            If you believe that you should have access please contact TROG for help.
                        </h5>
                    </div>
                </div>
            </div>
        </OnlinePatientManagementLayout>
    }

    return (
        <OnlinePatientManagementLayout
            menuItems={menuItems}
            footer={
                isMobile ?
                    undefined :
                    <Typography variant="caption">
                        Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2022 TROG Cancer Research | ABN 45 132 672 292
                    </Typography>
            }
        >
            <React.Suspense fallback={<RouteLoading />}>
                <Switch>
                    <CrfFormByCodesRoutes
                        path={[
                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-patient`
                        ]}
                        eventDefinitionCode="registration"
                        eventRepeat={1}
                        formDefinitionCode="general"
                        formRepeat={1}
                        createPatient
                        resolveBeforeLoad
                        formMapping={formMapping}
                    />
                    <CrfFormByCodesRoutes
                        path={[
                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                        ]}
                        resolveBeforeLoad
                        formMapping={formMapping}
                    />
                    <PatientSummaryByStudyNumberRoute
                        path={[
                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                        ]}
                        resolveBeforeLoad
                    >
                        <PatientSummary />
                    </PatientSummaryByStudyNumberRoute>
                    <InstitutionsByCodesRoute
                        path={[
                            `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                            '/registration'
                        ]}
                    >
                        <Registration />
                    </InstitutionsByCodesRoute>
                    <SaeByPatientStudyNumberRoute
                        path={[
                            `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/notify/:notifyMode`,
                        ]}
                        resolveBeforeLoad
                        key="sae-notification-page"
                    >
                        <SaeNotifyPage />
                    </SaeByPatientStudyNumberRoute>
                    <SaeByPatientStudyNumberRoute
                        path={[
                            `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/:viewMode`,
                            `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber`,
                        ]}
                        resolveBeforeLoad
                        key="sae-view-page"
                    >
                        <SaeViewPage />
                    </SaeByPatientStudyNumberRoute>
                    <InstitutionsByCodesRoute
                        path={[
                            `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                            '/sae'
                        ]}
                    >
                        <SaesPage />
                    </InstitutionsByCodesRoute>
                    <InstitutionsByCodesRoute
                        path={[
                            `/reports/:${onlinePatientManagement.routeParameters.institutionCode}`,
                            '/reports'
                        ]}>
                        <ReportsPage />
                    </InstitutionsByCodesRoute>
                    <InstitutionsByCodesRoute
                        path={[
                            `/screeningsummary/:${onlinePatientManagement.routeParameters.institutionCode}/:year`,
                            `/screeningsummary/:${onlinePatientManagement.routeParameters.institutionCode}`,
                            '/screeningsummary'
                        ]}
                    >
                        <ScreeningSummaryPage />
                    </InstitutionsByCodesRoute>
                    <Redirect path="*" to="/registration" />
                    <Redirect path="*" to="/registration/admin" />
                </Switch>
            </React.Suspense>
        </OnlinePatientManagementLayout>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;