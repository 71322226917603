/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Checkbox,
    Numeric,
    TextArea
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';


/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IProFormProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 3, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const eortcqlqC30FormCompletedYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.eortcQlqc30FormCompleted === Dtos.LookupYesNoType.Yes;
const eortcqlqhcC18FormCompletedYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.eortcQlqhcc18FormCompleted === Dtos.LookupYesNoType.Yes;
const eortcqlqNotCompleted = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.eortcQlqhcc18FormCompleted === Dtos.LookupYesNoType.No || formState?.values.eortcQlqc30FormCompleted === Dtos.LookupYesNoType.No;
const requiredAssistanceCompletingQuestionnaireYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.requiredAssistanceCompletingQuestionnaire === Dtos.LookupYesNoType.Yes;
const howWasQuestionnaireAdministeredOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.howWasQuestionnaireAdministered === Dtos.LookupProQuestionnaireAdministrationType.Other;
const languageUsedOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.languageUsed === Dtos.LookupProLanguageType.Other;
const appropriateReasonNonCompletionLanguage = ({ lookups, formState }: ICrfConditionParameters<Dtos.ProForm>) => formState?.values.appropriateReasonNonCompletion === Dtos.LookupProNonCompletionReasonType.NotAvailableInParticipantLanguage;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const ProForm: React.FunctionComponent<IProFormProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true, false);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.ProForm}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Field
                    name="assessmentDate"
                    component={KeyboardDatePicker}
                />
                <Field
                    name="eortcQlqc30FormCompleted"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="eortcQlqhcc18FormCompleted"
                    component={ToggleButtonGroup}
                />
                <FieldGroup>
                    <Field
                        name="requiredAssistanceCompletingQuestionnaire"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.ProForm}
                        mode="Enable"
                        condition={requiredAssistanceCompletingQuestionnaireYes}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="requiredAssistanceCompletingQuestionnaireSpecify"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="howWasQuestionnaireAdministered"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.ProForm}
                        mode="Enable"
                        condition={howWasQuestionnaireAdministeredOther}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="howWasQuestionnaireAdministeredSpecify"
                            component={Text}
                        />
                    </CrfCondition>                
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="languageUsed"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.ProForm}
                        mode="Enable"
                        condition={languageUsedOther}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="languageUsedSpecify"
                            component={Text}
                        />
                    </CrfCondition> 
                </FieldGroup>
                <FieldGroup>
                    <CrfCondition
                        type={Dtos.ProForm}
                        mode="Enable"
                        condition={eortcqlqNotCompleted}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="appropriateReasonNonCompletion"
                            component={Select}
                        />
                    </CrfCondition> 
                    
                    
                    <CrfCondition
                        type={Dtos.ProForm}
                        mode="Enable"
                        condition={appropriateReasonNonCompletionLanguage}
                        subscription={{ values: true }}
                    >
                        <Field
                            name="participantsLanguage"
                            component={Text}
                        />
                    </CrfCondition> 
                </FieldGroup>
                <CrfCondition
                    type={Dtos.ProForm}
                    mode="Enable"
                    condition={eortcqlqNotCompleted}
                    subscription={{ values: true }}
                >
                    <Field
                        name="nonCompletionIllnessRelated"
                        component={ToggleButtonGroup}
                    />
                </CrfCondition> 
                <Field
                    name="notes"
                    component={TextArea}
                />
                
                <CrfCondition
                    type={Dtos.ProForm}
                    mode="Show"
                    condition={eortcqlqC30FormCompletedYes}
                    subscription={{ values: true }}
                >
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        EORTC QLQ-C30
                    </Typography>
                    <Field
                        name="troubleDoingStrenuousActivities"
                        component={Select}
                    />
                    <Field
                        name="troubleTakingALongWalk"
                        component={Select}
                    />
                    <Field
                        name="troubleTakingShortWalkOutsideHouse"
                        component={Select}
                    />
                    <Field
                        name="needToStayInBedOrChairDuringDay"
                        component={Select}
                    />
                    <Field
                        name="helpEatingDressinWashingToilet"
                        component={Select}
                    />
                    <Field
                        name="limitedDoingWorkDailyActivities"
                        component={Select}
                    />
                    <Field
                        name="limitedPursuingHobbies"
                        component={Select}
                    />
                    <Field
                        name="shortOfBreath"
                        component={Select}
                    />
                    <Field
                        name="pain"
                        component={Select}
                    />
                    <Field
                        name="needARest"
                        component={Select}
                    />

                    <Field
                        name="troubleSleeping"
                        component={Select}
                    />
                    <Field
                        name="feltWeak"
                        component={Select}
                    />
                    <Field
                        name="lackedAppetite"
                        component={Select}
                    />
                    <Field
                        name="feltNauseated"
                        component={Select}
                    />
                    <Field
                        name="vomited"
                        component={Select}
                    />
                    <Field
                        name="constipated"
                        component={Select}
                    />
                    <Field
                        name="diarrhoea"
                        component={Select}
                    />
                    <Field
                        name="tired"
                        component={Select}
                    />
                    <Field
                        name="painInterferedDailyActivities"
                        component={Select}
                    />
                    <Field
                        name="difficultyConcentrating"
                        component={Select}
                    />

                    <Field
                        name="tense"
                        component={Select}
                    />
                    <Field
                        name="worry"
                        component={Select}
                    />
                    <Field
                        name="irritable"
                        component={Select}
                    />
                    <Field
                        name="depressed"
                        component={Select}
                    />
                    <Field
                        name="difficultyRememberingThings"
                        component={Select}
                    />
                    <Field
                        name="interferedFamily"
                        component={Select}
                    />
                    <Field
                        name="interferedSocial"
                        component={Select}
                    />
                    <Field
                        name="financialDifficulties"
                        component={Select}
                    />
                    <Field
                        name="overallHealth"
                        component={Select}
                    />
                    <Field
                        name="overallQualityOfLife"
                        component={Select}
                    />
                </CrfCondition>
                
                <CrfCondition
                    type={Dtos.ProForm}
                    mode="Show"
                    condition={eortcqlqhcC18FormCompletedYes}
                    subscription={{ values: true }}
                >
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        EORTC QLQ-HCC18
                    </Typography>
                    <Field
                        name="thirsty"
                        component={Select}
                    />
                    <Field
                        name="problemsWithSenseOfTaste"
                        component={Select}
                    />
                    <Field
                        name="lostMuscle"
                        component={Select}
                    />
                    <Field
                        name="abdominalSwelling"
                        component={Select}
                    />
                    <Field
                        name="concernedByAbdomenAppearance"
                        component={Select}
                    />
                    <Field
                        name="concernedBySkinOrEyes"
                        component={Select}
                    />
                    <Field
                        name="itching"
                        component={Select}
                    />
                    <Field
                        name="shoulderPain"
                        component={Select}
                    />
                    <Field
                        name="abdominalPain"
                        component={Select}
                    />
                    <Field
                        name="fevers"
                        component={Select}
                    />
                    <Field
                        name="chills"
                        component={Select}
                    />
                    <Field
                        name="worriedAboutGettingEnoughNourishment"
                        component={Select}
                    />
                    <Field
                        name="fullUpTooQuickly"
                        component={Select}
                    />
                    <Field
                        name="weightTooLow"
                        component={Select}
                    />
                    <Field
                        name="lessActive"
                        component={Select}
                    />
                    <Field
                        name="difficultToFinishThings"
                        component={Select}
                    />
                    <Field
                        name="neededSleepDuringTheDay"
                        component={Select}
                    />
                    <Field
                        name="affectedSexLife"
                        component={Select}
                    />
                </CrfCondition>
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default ProForm;
