/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password,
    KeyboardDatePicker,
    Input,
    FileUpload,
    IFormGridCell,
    FormGrid,
    IFileUpload,
    useFormState,
    FormDefinitionContext,
    ProgressButton,
    OnlinePatientManagementContext,
    useFormActions
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-trogapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, List, ListItem, ListItemText, ListItemIcon, Button } from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck';

import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes';


/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

//import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface ISourceDocumentsProps {
}

interface IInstructionsProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    },
    instructions: {
        margin: theme.spacing(0, 3, 1, 3),
        padding: theme.spacing(2, 1, 2, 1),
        color: theme.palette.primary.main
    },
    icon: {
        minWidth: '40px',
        marginLeft: '10px'
    },
    listItem: {
        paddingTop: 0,
        paddingBottom: 0
    },
    downloadButton: {
        padding: theme.spacing(3, 3, 0, 0),
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const existingLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/view/${file?.id}`;
}

const existingDownloadLink = (value?: IFileUpload) => {
    const file = value as Dtos.File;

    return `/opms/file/download/${file?.id}`;
}

const columns: Array<IFormGridCell<Dtos.SourceDocument>> = [
    {
        name: 'file',
        content: (
            <Input
                component={FileUpload}
                existingLink={existingLink}
                existingDownloadLink={existingDownloadLink}
            />
        )
    },
    {
        name: 'name',
        content: (
            <Input component={Text} />
        )
    },
    {
        name: 'fileTypeId',
        content: (
            <Input component={Select} />
        )
    },
]

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const Instructions: React.FunctionComponent<IInstructionsProps> = () => {
    const classes = useStyles();

    const { form: untypedForm } = React.useContext(FormContext);

    const form: Dtos.SourceDocumentForm | null = untypedForm as unknown as Dtos.SourceDocumentForm;

    const { values: formValues } = useFormState<Dtos.SourceDocumentForm,any>({ values: true });

    const sourceDocumentExist = React.useMemo(() => {
        let consentFormExist = false;
        let imagingAbdExist = false;
        let imagingCtExist = false;
        let imagingOtherExist = false;
        let bloodClinicalExist = false;
        let eligibilityConfirmationExist = false;
        let histologyExist = false;
        let worksheetExist = false;
        let otherExist = false;

        formValues?.sourceDocument?.forEach(sd => {
            switch (sd.fileTypeId) {
                case Dtos.LookupSourceDocumentType.ConsentForm: consentFormExist = true; break;
                case Dtos.LookupSourceDocumentType.ImagingAbd:imagingAbdExist = true; break;
                case Dtos.LookupSourceDocumentType.ImagingCt: imagingCtExist = true; break;
                case Dtos.LookupSourceDocumentType.ImagingOther: imagingOtherExist = true; break;
                case Dtos.LookupSourceDocumentType.BloodClinical: bloodClinicalExist = true; break;
                case Dtos.LookupSourceDocumentType.EligibilityConfirmation: eligibilityConfirmationExist = true; break;
                case Dtos.LookupSourceDocumentType.Histology: histologyExist = true; break;
                case Dtos.LookupSourceDocumentType.Worksheet: worksheetExist = true; break;
                case Dtos.LookupSourceDocumentType.Other: otherExist = true; break;
            }
        });

        return {
            consentForm: consentFormExist,
            imagingAbd: imagingAbdExist,
            imagingCt: imagingCtExist,
            imagingOther: imagingOtherExist,
            bloodClinical: bloodClinicalExist,
            eligibilityConfirmation: eligibilityConfirmationExist,
            histology: histologyExist,
            worksheet: worksheetExist,
            other: otherExist
        };
    }, [formValues]);

    return (
        <>
            <Alert
                icon={<></>}
                severity="info"
                className={classes.instructions}
            >
                <Typography component="div">
                    Please attach the following source documents:
                        <List>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.consentForm ? faCheck : faTimes} color={sourceDocumentExist.consentForm ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Consent form</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.imagingAbd ? faCheck : faTimes} color={sourceDocumentExist.imagingAbd ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Imaging: Abdominal</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.imagingCt ? faCheck : faTimes} color={sourceDocumentExist.imagingCt ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Imaging: Chest</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.imagingOther ? faCheck : faTimes} color={sourceDocumentExist.imagingOther ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Imaging: Other</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.bloodClinical ? faCheck : faTimes} color={sourceDocumentExist.bloodClinical ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Blood: clinical</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.eligibilityConfirmation ? faCheck : faTimes} color={sourceDocumentExist.eligibilityConfirmation ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Eligibility confirmation</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.histology ? faCheck : faTimes} color={sourceDocumentExist.histology ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Histology</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.worksheet ? faCheck : faTimes} color={sourceDocumentExist.worksheet ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Worksheet</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.other ? faCheck : faTimes} color={sourceDocumentExist.other ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>PROs</ListItemText>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                            <ListItemIcon><FontAwesomeIcon icon={sourceDocumentExist.other ? faCheck : faTimes} color={sourceDocumentExist.other ? "green" : "red"} className={classes.icon} /></ListItemIcon>
                            <ListItemText>Other</ListItemText>
                        </ListItem>
                    </List>
                    <p style={{ color: 'red' }}>Please ensure that you upload <strong>all source documentation including <u>all</u> worksheets</strong> i.e Eligibility sign off, tumour measurement etc. If your source document does not match one of the pre-determined file types, please upload the document as an 'Other' file type.</p>
                    <strong>Instructions:</strong> Click the "Add" button to add a source document then click "Browse..." to upload the source document and "Submit Form" to save changes to the system.
                    </Typography>
            </Alert>
        </>
    );
}

const SourceDocumentGrid = () => {

    const { values: formValues } = useFormState<Dtos.SourceDocumentForm, any>({ values: true });

    const { setFieldValue } = useFormActions();

    React.useEffect(() => {
        console.log(formValues.sourceDocument);
        formValues.sourceDocument?.forEach((doc, index) => {
            console.log(doc);
            if (!doc?.name && !!doc?.file?.upload?.name) {
                setFieldValue(`sourceDocument[${index}].name`, doc?.file?.upload?.name);
            }
        });
    }, [formValues, setFieldValue]);

    return (
        <>
            <FormGrid
                name="sourceDocument"
                columns={columns}
                type={Dtos.SourceDocument}
            />
        </>  
    );
}

const SourceDocuments: React.FunctionComponent<ISourceDocumentsProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const { formDefinition } = React.useContext(FormDefinitionContext);
    const { form: untypedForm } = React.useContext(FormContext);

    const form: Dtos.SourceDocumentForm | null = untypedForm as unknown as Dtos.SourceDocumentForm;

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const hasSourceDocument = React.useMemo(() => {
        return form?.sourceDocument?.length > 0
    }, [form]);



    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.SourceDocument}
                validateOn="onChange"
                canEdit={canUpdateCrf}
            >
                <Instructions />

                <div className={classes.downloadButton}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.icon}
                        component="a"
                        href={`/opms/file/download-all/${patient?.id}/${formDefinition?.id}/${form?.id}`}
                        disabled={!hasSourceDocument}
                    >
                        Download All Files
                </Button>
                </div>

                <SourceDocumentGrid />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default SourceDocuments;
