/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the reports landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { InstitutionsContext, InstitutionSelector, RouteLoading, TrialContextSelector, ALL_INSTITUTIONS_CODE, InstitutionBreadcrumbs } from '@ngt/opms';
import { RequestState } from '@ngt/request-utilities';
import { Column } from 'material-table';
import * as React from 'react';
import { useParams } from 'react-router';
import { createReportUrl, IReportParam, ReportExportFormat } from '../../utils/createReportUrl';
import ReportTable, { IReportItem } from './ReportsTable';
import * as Dtos from '../../api/dtos';
import { useHistory } from 'react-router-dom';
import { usePermissionsByIds } from '@ngt/opms-trogapi';
import reportSettings from '../../constants/reportSettings';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IReportsProps {
}

interface IReportParams {
    institutionCode?: string;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonSet: {
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));


const useReportColumns = (reportItems: IReportItem[]) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<IReportItem>> = [
            {
                field: 'reportTitle',
                title: 'Report',
                width: '15%'
            }
        ];

        return cols;
    }, [reportItems]);

    return columns;
};

const screeningLogPermissions: Dtos.Permission[] = [
    Dtos.Permission.ScreeningLogView
];

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister
];

const Reports: React.FunctionComponent<IReportsProps> = () => {
    const classes = useStyles();

    const history = useHistory();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewReports], screeningLogPermissionLoadState] = usePermissionsByIds(screeningLogPermissions, null, null, institution?.id, null, institution?.id != null ? true : false, false);

    const [[canAdminister], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, true, false);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/reports/${newInstitutionCode}`);
        }
        else {
            history?.push(`/reports`);
        }

        return;
    }, [history]);

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    React.useEffect(() => {
        if (screeningLogPermissionLoadState.state !== RequestState.Pending &&
            screeningLogPermissionLoadState.state !== RequestState.None &&
            permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewReports) {
                history.replace(`/error/403`);
            }
            else if (!canAdminister && institutionCode == null && institutions && institutions.length > 0) {
                history.replace(`/reports/${institutions[0].code}`)
            }
        }
    }, [institutions, canViewReports, permissionLoadState, screeningLogPermissionLoadState, history]);


    const defaultParams = React.useMemo(() => {
        const reportParams: IReportParam[] = [
            //...userKeyParam
        ];

        if (institutionCode) {
            reportParams.push({ paramName: 'instCode', paramValue: institutionCode } as IReportParam);
        }
        else if (canAdminister) {
            reportParams.push({ paramName: 'instCode', paramValue: '-1' } as IReportParam);
        }

        return reportParams;
    }, [institutionCode, canAdminister]);

    const noParams: IReportParam[] = [{
        paramName: "",
        paramValue: ""
    }]

    const reports: IReportItem[] = [
        {
            reportTitle: 'General Pre-Screening',
            reportFile: reportSettings.generalPreScreening,
            reportParams: defaultParams
        },
        {
            reportTitle: 'Ineligibility Reasons',
            reportFile: reportSettings.generalIneligibilityReasons,
            reportParams: defaultParams
        },
        {
            reportTitle: 'Screening Overview',
            reportFile: reportSettings.generalScreeningOverview,
            reportParams: defaultParams
        },
        //{
        //    reportTitle: 'Combined Eligibility Overview',
        //    reportFile: 'CombinedEligibilty',
        //    reportParams: defaultParams
        //}
    ];

    const reportColumns = useReportColumns(reports);
    
    const onReportClick = (event: React.MouseEvent<Element, MouseEvent>, rowData?: IReportItem | undefined) => {
        if (rowData) {
            const reportUrl: string = createReportUrl(rowData.reportFile,
                rowData.reportParams,
                rowData.reportFormat ? rowData.reportFormat as unknown as ReportExportFormat : undefined,
                true);

            window.open(reportUrl);
        }
    };

    return (
        <>
            <InstitutionBreadcrumbs />

            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    Reports
                </Typography>
                <Grid item xs={12} className={classes.mt3}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={canAdminister}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ? institution?.code : ALL_INSTITUTIONS_CODE}
                    />
                </Grid>
                <ReportTable
                    data={reports}
                    columns={reportColumns}
                    onClick={onReportClick}
                />
            </div>
        </>
    );
}

export default Reports;