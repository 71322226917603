/* Options:
Date: 2023-09-14 15:45:00
Version: 6.10
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44363

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetUserEmailAddresses,GetUserEmailAddressesResponse,GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,SaeWebActionTypeEnum
DefaultImports: SaeWebActionTypeEnum:@ngt/opms-sae
*/

import { SaeWebActionTypeEnum } from "@ngt/opms-sae";

export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IDelete
{
}

export enum SaeStatus
{
    Added = 0,
    New = 1,
    AwaitingForReview = 2,
    Approved = 3,
    Rejected = 4,
    Incorrect = 5,
}

export enum SaeRelatedness
{
    Unrelated = 1,
    Unlikely = 2,
    Possible = 3,
    Probable = 4,
    Definite = 5,
}

export enum SaeExpectedness
{
    Expected = 1,
    Unexpected = 2,
}

export class MedicalReviewItem
{
    public id?: number;
    public medicalReviewId?: number;
    public saeTreatmentCategoryId?: number;
    public typeId?: number;
    public dataEntered?: boolean;
    public latestRelatednessId?: number;
    public relatednessId?: SaeRelatedness;
    public expected?: SaeExpectedness;
    public comments: string;
    public notApplicable?: boolean;

    public constructor(init?: Partial<MedicalReviewItem>) { (Object as any).assign(this, init); }
}

export class MedicalReview
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public date?: string;
    public saeStatus?: SaeStatus;
    public narrativeSummary: string;
    public items: MedicalReviewItem[];

    public constructor(init?: Partial<MedicalReview>) { (Object as any).assign(this, init); }
}

export interface ISaeGetCollection extends IGet
{
}

export interface ISaeGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface ISaeGetSingleByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
    saeNumber?: number;
}

export class SaeWebActionType
{
    public id?: number;
    public value: string;

    public constructor(init?: Partial<SaeWebActionType>) { (Object as any).assign(this, init); }
}

export class WebAction
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public actionType?: SaeWebActionTypeEnum;
    public actionDate?: string;
    public userName: string;
    public notes: string;
    public recipients: string;
    public saeWebActionType: SaeWebActionType;

    public constructor(init?: Partial<WebAction>) { (Object as any).assign(this, init); }
}

export class SaeStudyDrugReviewForm
{
    public studyDrug: string;
    public investigatorAssessment: string;
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeStudyDrugReviewForm>) { (Object as any).assign(this, init); }
}

export class SaeExpectednessForm
{
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeExpectednessForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewForm
{
    public studyDrugReviews: SaeStudyDrugReviewForm[];
    public expectedness: SaeExpectednessForm[];
    public isEventStatusDeath: boolean;

    public constructor(init?: Partial<SaeMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export class FileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SaeDocumentForm
{
    public name: string;
    public file: File;

    public constructor(init?: Partial<SaeDocumentForm>) { (Object as any).assign(this, init); }
}

export enum SaeNotificationType
{
    NotifyInvestigator = 1,
    NotifyMedicalReviewer = 2,
}

export class SaeNotificationForm
{
    public recipients: number[];
    public ccRecipients: number[];
    public notes: string;
    public notifyStudyChair?: boolean;
    public notifySponsor?: boolean;
    public attachments: SaeDocumentForm[];
    public notificationType: SaeNotificationType;

    public constructor(init?: Partial<SaeNotificationForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningSummaryGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningSummaryGetCollection extends IGet
{
    institutionId?: number;
    year?: number;
}

export interface IScreeningSummaryGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export class ScreeningSummary implements IScreeningSummary
{
    public id?: number;
    public institutionId?: number;
    public institutionName: string;
    public summaryDate?: string;
    public endDate?: string;
    public multiFocalHcc?: number;
    public priorTreatmentForHcc?: number;
    public hccGreaterThan8Cm?: number;
    public childPughGreaterThanB7?: number;
    public ecogPerformanceStatusGreaterThan2?: number;
    public suitableForLiverResection?: number;
    public notSuitableForStudyTx?: number;
    public plannedForLiverTransplant?: number;
    public inadequateOrganAndMarrowFx?: number;
    public clinicallyEvidentAscites?: number;
    public knownOrPriorInvasiveMalignancy?: number;
    public otherEligibility?: number;
    public incompatibleComorbidities?: number;
    public unableToComply?: number;
    public siteRestrictions?: number;
    public declined?: number;
    public preScreened?: number;
    public numberConsented?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningSummary>) { (Object as any).assign(this, init); }
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

export class Patient implements IOpenClinicaPatient, IRandomisationPatient
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public previousPatientStateId?: number;

    public patientStateComment: string;
    public cohort?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    public studyNumber: string;
    public institutionCode: string;
    public randomisationNumber: string;
    public randomisationDate?: string;
    public dateOfBirth?: string;
    public exportedToOpenClinica?: string;
    public waivered?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class Bloods implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public protocolVersion?: number;

    // @Ignore()
    public startedSystemicTherapy?: number;

    // @Ignore()
    public dateOfSystemicTherapy?: string;

    public chemistryAssessmentDate?: string;
    public totalBilirubin?: number;
    public alt?: number;
    public ast?: number;
    public alp?: number;
    public ggt?: number;
    public albumin?: number;
    public sodium?: number;
    public creatinine?: number;
    public afp?: number;
    public haematologyAssessmentDate?: string;
    public haemoglobin?: number;
    public plateletCount?: number;
    public whiteCellCount?: number;
    public neutrophilCount?: number;
    public lymphocyteCount?: number;
    public coagulationAssessmentDate?: string;
    public prothrombinTime?: number;
    public partialThromboplastinTime?: number;
    public internationalNormalisedRatio?: number;
    public patientAntiCoagulants?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Bloods>) { (Object as any).assign(this, init); }
}

export class CancerDetails implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public protocolVersion?: number;

    public confirmedSingleNoduleHCC?: number;
    public methodOfHccNoduleDiagnosis?: number;
    public dateOfConfirmedHCC?: string;
    public previousDiagnosisOfHcc?: number;
    public earlyStageSolitaryHcc?: number;
    public previousHccSegment?: number;
    public previousHccSegmentOther: string;
    public previousHccTreated?: number;
    public evidenceOfResidualDisease?: number;
    public pughClass?: number;
    public pughPoints?: number;
    public bclcStage?: number;
    public liverResectionSuitable?: number;
    public liverResectionDeclined?: number;
    public liverTransplant?: number;
    public suitableForTreatment?: number;
    public mdtDate?: string;
    public hccSize?: number;
    public suitableForMwaRfa?: number;
    public sizeMoreThanThree?: boolean;
    public subcapsular?: boolean;
    public proximityToBowel?: boolean;
    public proximityToCentralBile?: boolean;
    public proximityToBloodVessels?: boolean;
    public subphrenic?: boolean;
    public suitableForTaceTare?: number;
    public hepBstatus?: number;
    public hepCstatus?: number;
    public hepTreatment?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<CancerDetails>) { (Object as any).assign(this, init); }
}

export class MedicalHistory implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public category?: number;
    public condition: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MedicalHistory>) { (Object as any).assign(this, init); }
}

export class ClinicalAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public sex?: number;

    public dateOfClinicalAssessment?: string;
    public height?: number;
    public weight?: number;
    public bmi?: number;
    public ecogPerformanceStatus?: number;
    public ascites?: number;
    public hepaticEncephalopathy?: number;
    public anyImportantHistory?: number;
    public medicalHistory: MedicalHistory[];
    public fertilityStatus?: number;
    public dateOfPregnancyTest?: string;
    public pregnancyTestResult?: number;
    public travelTime?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ClinicalAssessment>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedication implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public medicationName: string;
    public indication?: number;
    public indicationOther: string;
    public startDate: string;
    public ongoing?: boolean;
    public stopDate: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConcomitantMedication>) { (Object as any).assign(this, init); }
}

export class ConcomitantMedicationForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public anyMedications?: number;
    public concomitantMedication: ConcomitantMedication[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ConcomitantMedicationForm>) { (Object as any).assign(this, init); }
}

export class DemographicDetails implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public indigenousStatus?: number;
    public countryOfBirth: string;
    public primarySpokenLanguage: string;
    public languageSpokenAsAChild: string;
    public postcode: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DemographicDetails>) { (Object as any).assign(this, init); }
}

export class DiseaseAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public protocolVersion?: number;

    // @Ignore()
    public startedSystemicTherapy?: number;

    // @Ignore()
    public dateOfSystemicTherapy?: string;

    // @Ignore()
    public brainMetastases?: number;

    public assessmentDate?: string;
    public scanType?: number;
    public contrast?: number;
    public primaryTargetLesion?: number;
    public primaryTargetLesionOther: string;
    public arterialPhaseEnhancement?: number;
    public liverImagingReporting?: number;
    public mrecist?: number;
    public mrecistNotApplicable?: boolean;
    public recist?: number;
    public recistNotApplicable?: boolean;
    public extrahepaticHCCmetastasis?: number;
    public ctChestDate?: string;
    public ctHCCmetastases?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DiseaseAssessment>) { (Object as any).assign(this, init); }
}

export class EligibilityConfirmation implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public eligibilityConfirmed?: number;
    public eligibilityConfirmedBy: string;
    public dateConfirmed?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<EligibilityConfirmation>) { (Object as any).assign(this, init); }
}

export class General implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public siteName: string;

    public protocolVersion?: number;
    // @Ignore()
    public protocolVersionStr: string;

    public ageAtConsent?: number;
    public gender?: number;
    public hepatologistName: string;
    public radiationOncologistName: string;
    public interventionalRadiologistName: string;
    public dateOfConsent?: string;
    public qualitativeSubstudyConsent?: number;
    // @Ignore()
    public providedWrittenConsent?: number;

    public optedOutOfIndefiniteDataStorage?: number;
    public dateOptedOutOfIndefiniteDataStorage?: string;
    public servicesAustraliaConsent?: number;
    public servicesAustraliaDateOfConsent?: string;
    public consentDetails?: number;
    public dataCollectionStart?: string;
    public dataCollectionEnd?: string;
    // @Ignore()
    public dateRandomised?: string;

    // @Ignore()
    public cohort?: number;

    // @Ignore()
    public allocation?: number;

    // @Ignore()
    public regNo: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<General>) { (Object as any).assign(this, init); }
}

export class ProForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public assessmentDate?: string;
    public eortcQlqc30FormCompleted?: number;
    public eortcQlqhcc18FormCompleted?: number;
    public requiredAssistanceCompletingQuestionnaire?: number;
    public requiredAssistanceCompletingQuestionnaireSpecify: string;
    public howWasQuestionnaireAdministered?: number;
    public howWasQuestionnaireAdministeredSpecify: string;
    public languageUsed?: number;
    public languageUsedSpecify: string;
    public appropriateReasonNonCompletion?: number;
    public participantsLanguage: string;
    public nonCompletionIllnessRelated?: number;
    public notes: string;
    public troubleDoingStrenuousActivities?: number;
    public troubleTakingALongWalk?: number;
    public troubleTakingShortWalkOutsideHouse?: number;
    public needToStayInBedOrChairDuringDay?: number;
    public helpEatingDressinWashingToilet?: number;
    public limitedDoingWorkDailyActivities?: number;
    public limitedPursuingHobbies?: number;
    public shortOfBreath?: number;
    public pain?: number;
    public needARest?: number;
    public troubleSleeping?: number;
    public feltWeak?: number;
    public lackedAppetite?: number;
    public feltNauseated?: number;
    public vomited?: number;
    public constipated?: number;
    public diarrhoea?: number;
    public tired?: number;
    public painInterferedDailyActivities?: number;
    public difficultyConcentrating?: number;
    public tense?: number;
    public worry?: number;
    public irritable?: number;
    public depressed?: number;
    public difficultyRememberingThings?: number;
    public interferedFamily?: number;
    public interferedSocial?: number;
    public financialDifficulties?: number;
    public overallHealth?: number;
    public overallQualityOfLife?: number;
    public thirsty?: number;
    public problemsWithSenseOfTaste?: number;
    public lostMuscle?: number;
    public abdominalSwelling?: number;
    public concernedByAbdomenAppearance?: number;
    public concernedBySkinOrEyes?: number;
    public itching?: number;
    public shoulderPain?: number;
    public abdominalPain?: number;
    public fevers?: number;
    public chills?: number;
    public worriedAboutGettingEnoughNourishment?: number;
    public fullUpTooQuickly?: number;
    public weightTooLow?: number;
    public lessActive?: number;
    public difficultToFinishThings?: number;
    public neededSleepDuringTheDay?: number;
    public affectedSexLife?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ProForm>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class SourceDocumentForm implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sourceDocument: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<SourceDocumentForm>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EcrfAdminister = 40,
    DrugManagementAdministerPharmacyStock = 41,
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    Prerandomised = 1,
    UnderReview = 2,
    Randomised = 3,
    Ineligible = 7,
    Waivered = 8,
    Removed = 9,
}

export enum EventDefinitionType
{
    Registration = 1,
}

export enum FormDefinitionType
{
    General = 1,
    CancerDetails = 2,
    ClinicalAssessments = 3,
    MedicalHistory = 4,
    AdditionalEligibilityConfirmation = 5,
    AdverseEventForm = 6,
    AdverseEvent = 7,
    ConcomitantMedicationForm = 8,
    ConcomitantMedication = 9,
    DiseaseAssessment = 10,
    CtTargetLesion = 11,
    CtNonTargetLesion = 12,
    BmTargetLesion = 13,
    BmNonTargetLesion = 14,
    AdditionalImaging = 15,
    DemographicDetails = 16,
    Bloods = 17,
    Urinalysis = 18,
    Ecg = 19,
    BiomarkerSamples = 20,
    EligibilityConfirmation = 21,
    SourceDocuments = 22,
    SourceDocument = 23,
    ProForm = 24,
}

export enum SaeTypes
{
    Initial = 0,
    FollowUp1 = 1,
    FollowUp2 = 2,
    FollowUp3 = 3,
    FollowUp4 = 4,
    FollowUp5 = 5,
}

export enum TreatmentType
{
    SABR = 1,
    RFAMWA = 2,
    SOC = 3,
}

export enum LookupAscitesType
{
    Absent = 1,
    Mild = 2,
    ModerateSevere = 3,
}

export enum LookupBCLCstageType
{
    S0 = 1,
    SA = 2,
    SB = 3,
    SC = 4,
    SD = 5,
}

export enum LookupCategoryType
{
    Disease = 1,
    SurgicalProcedure = 2,
    AntiCancer = 3,
    PriorTreatmentHcc = 4,
}

export enum LookupEcogType
{
    Ecog0 = 1,
    Ecog1 = 2,
    Ecog2 = 3,
    Ecog3 = 4,
    Ecog4 = 5,
}

export enum LookupFertilityStatusType
{
    PostMenopausal = 1,
    IrreversibleSurgicalSterilisation = 2,
    PeriOrPremenopausal = 3,
}

export enum LookupHepaticEncephalopathyType
{
    None = 1,
    Grade12 = 2,
    Grade34 = 3,
}

export enum LookupIndicationType
{
    HCCtreatment = 1,
    ConcomitantTherapy = 2,
    AEtreatment = 3,
    ProphylaxisTreatment = 4,
    Other = 5,
}

export enum LookupIndigenousStatusType
{
    Indigenous = 1,
    NotIndigenous = 2,
    Unknown = 3,
}

export enum LookupPositiveNegativeType
{
    Positive = 1,
    Negative = 2,
}

export enum LookupSexType
{
    Male = 1,
    Female = 2,
    Other = 3,
}

export enum LookupSourceDocumentType
{
    ConsentForm = 1,
    ImagingAbd = 2,
    ImagingCt = 3,
    ImagingOther = 4,
    BloodClinical = 5,
    EligibilityConfirmation = 6,
    Histology = 7,
    Worksheet = 8,
    Other = 9,
    PROs = 10,
}

export enum LookupYesNoType
{
    No = 0,
    Yes = 1,
}

export enum LookupSegmentType
{
    S1 = 1,
    S2 = 2,
    S3 = 3,
    S4 = 4,
    S5 = 5,
    S6 = 6,
    S7 = 7,
    S8 = 8,
    Other = 9,
}

export enum LookupProNonCompletionReasonType
{
    DidNotReturn = 1,
    RefusedToComplete = 2,
    UnableToContact = 3,
    MissedAppointment = 4,
    WithdrewFromStudy = 5,
    IncorrectQuestionnaire = 6,
    NotAvailableInParticipantLanguage = 7,
}

export enum LookupProLanguageType
{
    English = 1,
    Arabic = 2,
    ChineseCantonese = 3,
    ChineseMandarin = 4,
    Greek = 5,
    Italian = 6,
    Hindi = 7,
    Vietnamese = 8,
    Other = 9,
}

export enum LookupProQuestionnaireAdministrationType
{
    Clinic = 2,
    Telephone = 3,
    Posted = 4,
    Other = 5,
}

export enum LookupProResponseType
{
    NotAtAll = 1,
    ALittleBit = 2,
    QuiteABit = 3,
    VeryMuch = 4,
    Unobtainable = 99,
}

export enum LookupProRateType
{
    One = 1,
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
    Seven = 7,
    Unobtainable = 99,
}

export enum LookupProtocolVersionType
{
    V12 = 1,
    V2 = 2,
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class SaeFormValidationError implements ISaeFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<SaeFormValidationError>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationResult
{
    public result: ValidationResultType;
    public errors: SaeFormValidationError[];

    public constructor(init?: Partial<SaeFormValidationResult>) { (Object as any).assign(this, init); }
}

export enum SaeMedicalReviewStatus
{
    NotNotified = 0,
    Pending = 1,
    Reviewed = 3,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class Sae<TSaeForm, TPatient>
{
    public patient: TPatient;
    public form: TSaeForm;
    public status: SaeStatus;
    public ecrfs: TSaeForm[];
    public saeFormItems: { [index: string]: string; };
    public medicalReview: MedicalReview;
    public medicalReviewStatus: SaeMedicalReviewStatus;
    public actionHistory: WebAction[];
    public lookups: Lookup[];

    public constructor(init?: Partial<Sae<TSaeForm, TPatient>>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationError implements IScreeningSummaryFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningSummaryFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningSummaryFormValidationError[];

    public constructor(init?: Partial<ScreeningSummaryFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export interface IScreeningSummary extends IDataModel
{
    institutionId?: number;
    institutionName: string;
    summaryDate?: string;
    endDate?: string;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IOpenClinicaPatient extends IPatient
{
    exportedToOpenClinica?: string;
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IRandomisationPatient
{
    randomisationNumber: string;
    randomisationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IOpenClinicaForm extends IForm
{
    exportedToOpenClinica?: string;
}

export interface ISaeFormValidationError extends IValidationError
{
    property: string;
}

export interface ISaeFormValidationResult extends IValidationResult
{
    errors: ISaeFormValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export class RelevantMedicationHistory implements ISaeSubform
{
    public ordinal: number;
    public relevantMedicalHistorySpecify: string;
    public relevantMedicalHistoryStart?: string;
    public relevantMedicalHistoryEnd?: string;
    public relevantMedicalHistoryOngoing?: boolean;

    public constructor(init?: Partial<RelevantMedicationHistory>) { (Object as any).assign(this, init); }
}

export class EventTreatment implements ISaeSubform
{
    public ordinal: number;
    public investName: string;
    public investDate?: string;
    public investResult: string;
    public investPending?: boolean;

    public constructor(init?: Partial<EventTreatment>) { (Object as any).assign(this, init); }
}

export class PrescribedMedications implements ISaeSubform
{
    public ordinal: number;
    public prescribeName: string;
    public prescribeIndication: string;
    public prescribeStart?: string;
    public prescribeEnd?: string;
    public prescribeOngoing?: boolean;
    public prescribeDose?: number;
    public prescribeUnit: string;
    public prescribeUnitOther: string;
    public prescribeRoute: string;
    public prescribeFrequency: string;
    public prescribeFrequencyOther: string;

    public constructor(init?: Partial<PrescribedMedications>) { (Object as any).assign(this, init); }
}

export class SaeForm implements ISaeForm
{
    public id?: number;
    public patientId?: number;
    public saeNumber?: number;
    public followUpNumber?: number;
    public investigator: string;
    public ctcaeTerm: string;
    public ctcaeGrade?: number;
    public ctcaeSpec: string;
    public onsetDate?: string;
    public resultsInDeath: string;
    public isLifeThreatening: string;
    public hospitalisationOrProlongExisting: string;
    public admissionDate?: string;
    public dischargeDate?: string;
    public ongoing?: boolean;
    public persistentDisability: string;
    public congenitalAnomalyBirthDefect: string;
    public importantMedicalEvent: string;
    public relationshipToTreatment: string;
    public progressiveDisease: string;
    public trialProcedure: string;
    public trialProcedureName: string;
    public trialProcedureIndication: string;
    public trialProcedureDate?: string;
    public concurrentDisorder: string;
    public concurrentDisorderSpecify: string;
    public concurrentMedication: string;
    public medName: string;
    public medIndication: string;
    public medStart?: string;
    public medStop?: string;
    public medOngoing?: boolean;
    public medDose?: number;
    public medUnit: string;
    public medUnitOther: string;
    public medRoute: string;
    public medFrequency: string;
    public medFrequencyOther: string;
    public otherCause: string;
    public otherCauseSpecify: string;
    public eventDescription: string;
    public mwa?: boolean;
    public rfa?: boolean;
    public tace?: boolean;
    public tare?: boolean;
    public sabr?: boolean;
    public treatmentStart?: string;
    public treatmentEnd?: string;
    public doseReceived?: number;
    public treatmentModification: string;
    public relevantMedicalHistory: string;
    public investigativeProcedures: string;
    public medsPrescribed: string;
    public eventStatus: string;
    public describeSequelae: string;
    public causeOfDeath: string;
    public dateOfAutopsy?: string;
    public reasonUnknown: string;
    public dateResolved?: string;
    public relevantMedicationHistory: RelevantMedicationHistory[];
    public eventTreatment: EventTreatment[];
    public prescribedMedications: PrescribedMedications[];
    public dateCreated?: string;
    public dateUpdated?: string;
    public unassignedProperties: { [index: string]: Object; };
    public inconvertableProperties: { [index: string]: string; };

    public constructor(init?: Partial<SaeForm>) { (Object as any).assign(this, init); }
}

export interface ISaeForm extends IModel
{
    patientId?: number;
    saeNumber?: number;
    followUpNumber?: number;
    eventStatus: string;
    dateCreated?: string;
    dateUpdated?: string;
    unassignedProperties: { [index: string]: Object; };
    inconvertableProperties: { [index: string]: string; };
}

export interface IScreeningSummaryFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningSummaryFormValidationResult extends IValidationResult
{
    errors: IScreeningSummaryFormValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface ISaeSubform
{
    ordinal: number;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class GetSiteApprovedProtocolVersionResponse
{
    public versionId?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetSiteApprovedProtocolVersionResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientIneligibleEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientIneligibleEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientSubmittedForReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReassignedToSiteEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendRegistrationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRegistrationEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientsOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientsOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class SendRandomisationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendRandomisationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvestigatorReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendNewSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNewSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnapprovedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnapprovedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnresolvedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnresolvedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetEcrfSettingsResponse
{
    public ecrfUrl: string;
    public trialOid: string;
    public studyName: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEcrfSettingsResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public saeType?: SaeTypes;
    public treatmentType?: TreatmentType;
    public lookupAscitesType?: LookupAscitesType;
    public lookupBCLCstageType?: LookupBCLCstageType;
    public lookupCategoryType?: LookupCategoryType;
    public lookupEcogType?: LookupEcogType;
    public lookupFertilityStatusType?: LookupFertilityStatusType;
    public lookupHepaticEncephalopathyType?: LookupHepaticEncephalopathyType;
    public lookupIndicationType?: LookupIndicationType;
    public lookupIndigenousStatusType?: LookupIndigenousStatusType;
    public lookupPositiveNegativeType?: LookupPositiveNegativeType;
    public lookupSexType?: LookupSexType;
    public lookupSourceDocumentType?: LookupSourceDocumentType;
    public lookupYesNoType?: LookupYesNoType;
    public lookupSegmentType?: LookupSegmentType;
    public lookupProNonCompletionReasonType?: LookupProNonCompletionReasonType;
    public lookupProLanguageType?: LookupProLanguageType;
    public lookupProQuestionnaireAdministrationType?: LookupProQuestionnaireAdministrationType;
    public lookupProResponseType?: LookupProResponseType;
    public lookupProRateType?: LookupProRateType;
    public lookupProtocolVersionType?: LookupProtocolVersionType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewSingleByPatientStudyNumberResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumberResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewNarrativeSummaryPostSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewPostSaveResponse
{
    public responseStatus: ResponseStatus;
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateMedicalReviewResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateMedicalReviewResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved saes.
    */
    // @DataMember(Order=2)
    public saes: Sae<SaeForm,Patient>[];

    public constructor(init?: Partial<SaeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sae.
    */
    // @DataMember(Order=2)
    public sae: Sae<SaeForm, Patient>;

    public constructor(init?: Partial<SaeSingleResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetCollectionByStatusResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae<ISaeForm,IPatient>[];

    public constructor(init?: Partial<SaeGetCollectionByStatusResponse>) { (Object as any).assign(this, init); }
}

export class SaeWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeSendNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeSendNotificationResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateNotificationFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateNotificationFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateRejectionFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateRejectionFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummarySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummarySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningSummaryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeningsummary.
    */
    // @DataMember(Order=2)
    public screeningSummary: ScreeningSummary[];

    public constructor(init?: Partial<ScreeningSummaryCollectionResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningSummaryFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningSummaryFormValidationResult;

    public constructor(init?: Partial<ScreeningSummaryFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definition.
    */
    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definitions.
    */
    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient state.
    */
    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient states.
    */
    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating group.
    */
    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating groups.
    */
    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event.
    */
    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved events.
    */
    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloods.
    */
    // @DataMember(Order=2)
    public form: Bloods;

    public constructor(init?: Partial<BloodsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved bloodss.
    */
    // @DataMember(Order=2)
    public forms: Bloods[];

    public constructor(init?: Partial<BloodsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BloodsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BloodsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BloodsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved cancer details.
    */
    // @DataMember(Order=2)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved cancer detailss.
    */
    // @DataMember(Order=2)
    public forms: CancerDetails[];

    public constructor(init?: Partial<CancerDetailsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CancerDetailsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CancerDetailsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CancerDetailsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessment.
    */
    // @DataMember(Order=2)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved clinical assessments.
    */
    // @DataMember(Order=2)
    public forms: ClinicalAssessment[];

    public constructor(init?: Partial<ClinicalAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ClinicalAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ClinicalAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ClinicalAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medications.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedication[];

    public constructor(init?: Partial<ConcomitantMedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication form.
    */
    // @DataMember(Order=2)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved concomitant medication forms.
    */
    // @DataMember(Order=2)
    public forms: ConcomitantMedicationForm[];

    public constructor(init?: Partial<ConcomitantMedicationFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConcomitantMedicationFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConcomitantMedicationFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved demographic details.
    */
    // @DataMember(Order=2)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved demographic detailss.
    */
    // @DataMember(Order=2)
    public forms: DemographicDetails[];

    public constructor(init?: Partial<DemographicDetailsCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DemographicDetailsDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DemographicDetailsValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DemographicDetailsValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessment.
    */
    // @DataMember(Order=2)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved disease assessments.
    */
    // @DataMember(Order=2)
    public forms: DiseaseAssessment[];

    public constructor(init?: Partial<DiseaseAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DiseaseAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiseaseAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DiseaseAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmation.
    */
    // @DataMember(Order=2)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility confirmations.
    */
    // @DataMember(Order=2)
    public forms: EligibilityConfirmation[];

    public constructor(init?: Partial<EligibilityConfirmationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityConfirmationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityConfirmationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityConfirmationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general.
    */
    // @DataMember(Order=2)
    public form: General;

    public constructor(init?: Partial<GeneralSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved generals.
    */
    // @DataMember(Order=2)
    public forms: General[];

    public constructor(init?: Partial<GeneralCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GeneralDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<GeneralValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistorySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical history.
    */
    // @DataMember(Order=2)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved medical historys.
    */
    // @DataMember(Order=2)
    public forms: MedicalHistory[];

    public constructor(init?: Partial<MedicalHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicalHistoryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicalHistoryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicalHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pro form.
    */
    // @DataMember(Order=2)
    public form: ProForm;

    public constructor(init?: Partial<ProFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pro forms.
    */
    // @DataMember(Order=2)
    public forms: ProForm[];

    public constructor(init?: Partial<ProFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document form.
    */
    // @DataMember(Order=2)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document forms.
    */
    // @DataMember(Order=2)
    public forms: SourceDocumentForm[];

    public constructor(init?: Partial<SourceDocumentFormCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentFormDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentFormValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class GetUserNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<GetUserNamesResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @Route("/opms/protocol/version/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetSiteApprovedProtocolVersion implements IReturn<GetSiteApprovedProtocolVersionResponse>
{
    public instId?: number;

    public constructor(init?: Partial<GetSiteApprovedProtocolVersion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSiteApprovedProtocolVersion'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetSiteApprovedProtocolVersionResponse(); }
}

export class SendPatientIneligibleEmail implements IReturn<SendPatientIneligibleEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientIneligibleEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientIneligibleEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientIneligibleEmailResponse(); }
}

export class SendPatientSubmittedForReviewEmail implements IReturn<SendPatientSubmittedForReviewEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientSubmittedForReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientSubmittedForReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientSubmittedForReviewEmailResponse(); }
}

export class SendPatientReassignedToSiteEmail implements IReturn<SendPatientReassignedToSiteEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientReassignedToSiteEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReassignedToSiteEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReassignedToSiteEmailResponse(); }
}

export class SendRegistrationEmail implements IReturn<SendRegistrationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRegistrationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRegistrationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRegistrationEmailResponse(); }
}

export class QueuePatientOcTransfer implements IReturn<QueuePatientOcTransferResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<QueuePatientOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientOcTransferResponse(); }
}

export class QueuePatientsOcTransfer implements IReturn<QueuePatientsOcTransferResponse>
{

    public constructor(init?: Partial<QueuePatientsOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientsOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientsOcTransferResponse(); }
}

export class SendRandomisationEmail implements IReturn<SendRandomisationEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendRandomisationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendRandomisationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendRandomisationEmailResponse(); }
}

export class SendSaeNotifyInvestigatorEmail implements IReturn<SendSaeNotifyInvestigatorEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyInvestigatorEmailResponse(); }
}

export class SendSaeInvestigatorReviewEmail implements IReturn<SendSaeInvestigatorReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public reasonForRejection: string;
    public reviewer: string;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvestigatorReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvestigatorReviewEmailResponse(); }
}

export class SendNewSaeEmail implements IReturn<SendNewSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendNewSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNewSaeEmailResponse(); }
}

export class SendUnapprovedSaeEmail implements IReturn<SendUnapprovedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnapprovedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnapprovedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnapprovedSaeEmailResponse(); }
}

export class SendUnresolvedSaeEmail implements IReturn<SendUnresolvedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnresolvedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnresolvedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnresolvedSaeEmailResponse(); }
}

// @Route("/opms/ecrf/settings/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetEcrfSettings implements IReturn<GetEcrfSettingsResponse>
{

    public constructor(init?: Partial<GetEcrfSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEcrfSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEcrfSettingsResponse(); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

// @Route("/openiddict/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/openiddict/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/opms/sae/medicalreview/{PatientStudyNumber}/{SaeNumber}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewSingleByPatientStudyNumber implements IReturn<SaeMedicalReviewSingleByPatientStudyNumberResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="string")
    public patientStudyNumber: string;

    /**
    * The sae number
    */
    // @DataMember(Order=2)
    // @ApiMember(Description="The sae number", IsRequired=true, Name="SaeNumber", ParameterType="int")
    public saeNumber: number;

    public constructor(init?: Partial<SaeMedicalReviewSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeMedicalReviewSingleByPatientStudyNumberResponse(); }
}

// @Route("/opms/sae/medicalreview/save-narrative-summary", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewNarrativeSummaryPostSave implements IReturn<SaeMedicalReviewNarrativeSummaryPostSaveResponse>
{
    /**
    * The medical review id of the narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The medical review id of the narrative to save", IsRequired=true, Name="MedicalReviewId", ParameterType="body")
    public medicalReviewId: number;

    /**
    * The narrative to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The narrative to save", IsRequired=true, Name="NarrativeSummary", ParameterType="body")
    public narrativeSummary: string;

    public constructor(init?: Partial<SaeMedicalReviewNarrativeSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewNarrativeSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewNarrativeSummaryPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewPostSave implements IReturn<SaeMedicalReviewPostSaveResponse>
{
    /**
    * The medical review to save
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="model", Description="The medical review to save", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeMedicalReviewPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewPostSaveResponse(); }
}

// @Route("/opms/sae/medicalreview/validate", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateMedicalReview implements IReturn<SaeValidateMedicalReviewResponse>
{
    /**
    * The medical review
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="MedicalReview", Description="The medical review", IsRequired=true, Name="MedicalReview", ParameterType="body")
    public medicalReview: MedicalReview;

    public constructor(init?: Partial<SaeValidateMedicalReview>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateMedicalReview'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateMedicalReviewResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollection implements IReturn<SaeCollectionResponse>, ISaeGetCollection
{

    public constructor(init?: Partial<SaeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollectionByInstitutionCode implements IReturn<SaeCollectionResponse>, ISaeGetCollectionByInstitutionCode
{
    /**
    * The code of the Institution associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<SaeGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/single/patient-study-number/{PatientStudyNumber}/sae-number/{SaeNumber}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=500)
// @DataContract
export class SaeGetSingleByPatientStudyNumber implements IReturn<SaeSingleResponse>, ISaeGetSingleByPatientStudyNumber
{
    /**
    * The study number of the Patient associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The sae number of the Sae associated with the sae to retrieve.
    */
    // @DataMember(Order=2)
    public saeNumber: number;

    public constructor(init?: Partial<SaeGetSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeSingleResponse(); }
}

// @Route("/opms/sae/collection/status", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetCollectionByStatus implements IReturn<SaeGetCollectionByStatusResponse>
{
    /**
    * The status of the saes
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The status of the saes", IsRequired=true, Name="Status", ParameterType="body")
    public status: SaeStatus;

    public constructor(init?: Partial<SaeGetCollectionByStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetCollectionByStatusResponse(); }
}

// @Route("/opms/sae/webaction", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeWebActionSave implements IReturn<SaeWebActionSaveResponse>
{
    /**
    * The sae web action
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The sae web action", IsRequired=true, Name="WebAction", ParameterType="model")
    public webAction: WebAction;

    public constructor(init?: Partial<SaeWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeWebActionSaveResponse(); }
}

// @Route("/opms/sae/webaction/medicalreview", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewWebActionSave implements IReturn<SaeMedicalReviewWebActionSaveResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=2)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The medical review form from which the sae web action will be based off
    */
    // @DataMember(Order=3)
    // @ApiMember(Description="The medical review form from which the sae web action will be based off", IsRequired=true, Name="SaeMedicalReviewForm", ParameterType="model")
    public medicalReview: SaeMedicalReviewForm;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewWebActionSaveResponse(); }
}

// @Route("/opms/sae/send-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeSendNotification implements IReturn<SaeSendNotificationResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public notificationForm: SaeNotificationForm;

    public constructor(init?: Partial<SaeSendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeSendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeSendNotificationResponse(); }
}

// @Route("/opms/sae/validate-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateNotificationForm implements IReturn<SaeValidateNotificationFormResponse>
{
    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public form: SaeNotificationForm;

    public constructor(init?: Partial<SaeValidateNotificationForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateNotificationForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateNotificationFormResponse(); }
}

// @Route("/opms/sae/validate-rejection", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateRejectionForm implements IReturn<SaeValidateRejectionFormResponse>
{
    /**
    * The rejection form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="WebAction", Description="The rejection form", IsRequired=true, Name="RejectionForm", ParameterType="body")
    public form: WebAction;

    public constructor(init?: Partial<SaeValidateRejectionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateRejectionForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateRejectionFormResponse(); }
}

// @Route("/opms/sae/open-clinica/document", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetOpenClinicaDocument implements IReturn<Blob>, IGet
{
    /**
    * The file name of the document in openclinica
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The file name of the document in openclinica", IsRequired=true, Name="FileName", ParameterType="body")
    public fileName: string;

    public constructor(init?: Partial<SaeGetOpenClinicaDocument>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetOpenClinicaDocument'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/single/id/{Id}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetSingleById implements IReturn<ScreeningSummarySingleResponse>, IScreeningSummaryGetSingleById
{
    /**
    * The ID of the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningSummaryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollection implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollection
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    /**
    * The Year associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=2)
    public year?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummaryCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryGetCollectionByInstitutionId implements IReturn<ScreeningSummaryCollectionResponse>, IScreeningSummaryGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the screeningsummary to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningSummaryGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningSummaryCollectionResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/save", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningSummary.ScreeningSummarySingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostSave implements IReturn<ScreeningSummarySingleResponse>
{
    /**
    * The screeningsummary to save.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummarySingleResponse(); }
}

/**
* ScreeningSummary
*/
// @Route("/opms/screeningsummary/validate", "POST")
// @Api(Description="ScreeningSummary")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningSummaryFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningSummaryPostValidate implements IReturn<ScreeningSummaryFormValidationResponse>
{
    /**
    * The screeningsummary to validate.
    */
    // @DataMember(Order=1)
    public screeningSummary: ScreeningSummary;

    public constructor(init?: Partial<ScreeningSummaryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningSummaryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningSummaryFormValidationResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

/**
* Lookup
*/
// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    /**
    * The ID of the patient state to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    /**
    * The ID of the patient to validate.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    /**
    * The Study Number of the patient to validate.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    /**
    * The Code of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    /**
    * The ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    /**
    * The Patient ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Id of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    /**
    * The Patient Study Number of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Code of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The ID of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The Code of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/id/{Id}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleById implements IReturn<BloodsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/ids", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleByIds implements IReturn<BloodsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods if no bloods is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/single/codes", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsGetSingleByCodes implements IReturn<BloodsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new bloods if no bloods is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BloodsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollection implements IReturn<BloodsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BloodsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollectionByIds implements IReturn<BloodsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsCollectionResponse)", StatusCode=500)
// @DataContract
export class BloodsGetCollectionByCodes implements IReturn<BloodsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BloodsCollectionResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSave implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save/ids", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSaveWithIds implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The ID of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/save/codes", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsSingleResponse)", StatusCode=500)
// @DataContract
export class BloodsPostSaveWithCodes implements IReturn<BloodsSingleResponse>
{
    /**
    * The bloods to save.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The Code of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BloodsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsSingleResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDelete implements IReturn<BloodsDeleteResponse>
{
    /**
    * The bloods to delete.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteWithId implements IReturn<BloodsDeleteResponse>
{
    /**
    * The ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BloodsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/ids", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteWithIds implements IReturn<BloodsDeleteResponse>
{
    /**
    * The Patient ID of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/delete/codes", "DELETE")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsDeleteResponse)", StatusCode=500)
// @DataContract
export class BloodsDeleteByCodes implements IReturn<BloodsDeleteResponse>
{
    /**
    * The Patient Study Number of the bloods to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BloodsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BloodsDeleteResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidate implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    public constructor(init?: Partial<BloodsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate/ids", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidateWithIds implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The ID of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Bloods
*/
// @Route("/opms/form/bloods/validate/codes", "POST")
// @Api(Description="Form - Bloods")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BloodsValidationResponse)", StatusCode=500)
// @DataContract
export class BloodsPostValidateWithCodes implements IReturn<BloodsValidationResponse>
{
    /**
    * The bloods to validate.
    */
    // @DataMember(Order=1)
    public form: Bloods;

    /**
    * The Code of the Institution associated with the bloods to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the bloods to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the bloods to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the bloods to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BloodsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BloodsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BloodsValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/id/{Id}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleById implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CancerDetailsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/ids", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleByIds implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new cancer details if no cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CancerDetailsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/single/codes", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetSingleByCodes implements IReturn<CancerDetailsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new cancer details if no cancer details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CancerDetailsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollection implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CancerDetailsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollectionByIds implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsGetCollectionByCodes implements IReturn<CancerDetailsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CancerDetailsCollectionResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSave implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save/ids", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSaveWithIds implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The ID of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CancerDetailsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/save/codes", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostSaveWithCodes implements IReturn<CancerDetailsSingleResponse>
{
    /**
    * The cancer details to save.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The Code of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CancerDetailsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsSingleResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDelete implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The cancer details to delete.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteWithId implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CancerDetailsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/ids", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteWithIds implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The Patient ID of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CancerDetailsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/delete/codes", "DELETE")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsDeleteByCodes implements IReturn<CancerDetailsDeleteResponse>
{
    /**
    * The Patient Study Number of the cancer details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CancerDetailsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CancerDetailsDeleteResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidate implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    public constructor(init?: Partial<CancerDetailsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate/ids", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidateWithIds implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The ID of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Cancer Details
*/
// @Route("/opms/form/cancer-details/validate/codes", "POST")
// @Api(Description="Form - Cancer Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CancerDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class CancerDetailsPostValidateWithCodes implements IReturn<CancerDetailsValidationResponse>
{
    /**
    * The cancer details to validate.
    */
    // @DataMember(Order=1)
    public form: CancerDetails;

    /**
    * The Code of the Institution associated with the cancer details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the cancer details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the cancer details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the cancer details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CancerDetailsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CancerDetailsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CancerDetailsValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleById implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/ids", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByIds implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/single/codes", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetSingleByCodes implements IReturn<ClinicalAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new clinical assessment if no clinical assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollection implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ClinicalAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByIds implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentGetCollectionByCodes implements IReturn<ClinicalAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClinicalAssessmentCollectionResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSave implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithIds implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/save/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostSaveWithCodes implements IReturn<ClinicalAssessmentSingleResponse>
{
    /**
    * The clinical assessment to save.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ClinicalAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentSingleResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDelete implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The clinical assessment to delete.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithId implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteWithIds implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentDeleteByCodes implements IReturn<ClinicalAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ClinicalAssessmentDeleteResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidate implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    public constructor(init?: Partial<ClinicalAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/ids", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithIds implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The ID of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Clinical Assessment
*/
// @Route("/opms/form/clinical-assessment/validate/codes", "POST")
// @Api(Description="Form - Clinical Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ClinicalAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class ClinicalAssessmentPostValidateWithCodes implements IReturn<ClinicalAssessmentValidationResponse>
{
    /**
    * The clinical assessment to validate.
    */
    // @DataMember(Order=1)
    public form: ClinicalAssessment;

    /**
    * The Code of the Institution associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the clinical assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ClinicalAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClinicalAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClinicalAssessmentValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleById implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByIds implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetSingleByCodes implements IReturn<ConcomitantMedicationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication if no concomitant medication is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollection implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByIds implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationGetCollectionByCodes implements IReturn<ConcomitantMedicationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationCollectionResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSave implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithIds implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostSaveWithCodes implements IReturn<ConcomitantMedicationSingleResponse>
{
    /**
    * The concomitant medication to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationSingleResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDelete implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The concomitant medication to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithId implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteWithIds implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationDeleteByCodes implements IReturn<ConcomitantMedicationDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationDeleteResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidate implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    public constructor(init?: Partial<ConcomitantMedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithIds implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The ID of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication
*/
// @Route("/opms/form/concomitant-medication/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationPostValidateWithCodes implements IReturn<ConcomitantMedicationValidationResponse>
{
    /**
    * The concomitant medication to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedication;

    /**
    * The Code of the Institution associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/id/{Id}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleById implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/ids", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByIds implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/single/codes", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetSingleByCodes implements IReturn<ConcomitantMedicationFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new concomitant medication form if no concomitant medication form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollection implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByIds implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormGetCollectionByCodes implements IReturn<ConcomitantMedicationFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConcomitantMedicationFormCollectionResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSave implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithIds implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/save/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormSingleResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostSaveWithCodes implements IReturn<ConcomitantMedicationFormSingleResponse>
{
    /**
    * The concomitant medication form to save.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConcomitantMedicationFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormSingleResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDelete implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The concomitant medication form to delete.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithId implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/ids", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteWithIds implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient ID of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/delete/codes", "DELETE")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormDeleteByCodes implements IReturn<ConcomitantMedicationFormDeleteResponse>
{
    /**
    * The Patient Study Number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConcomitantMedicationFormDeleteResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidate implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/ids", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithIds implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The ID of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Concomitant Medication Form
*/
// @Route("/opms/form/concomitant-medication-form/validate/codes", "POST")
// @Api(Description="Form - Concomitant Medication Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConcomitantMedicationFormValidationResponse)", StatusCode=500)
// @DataContract
export class ConcomitantMedicationFormPostValidateWithCodes implements IReturn<ConcomitantMedicationFormValidationResponse>
{
    /**
    * The concomitant medication form to validate.
    */
    // @DataMember(Order=1)
    public form: ConcomitantMedicationForm;

    /**
    * The Code of the Institution associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the concomitant medication form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConcomitantMedicationFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConcomitantMedicationFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConcomitantMedicationFormValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/id/{Id}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleById implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicDetailsGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/ids", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleByIds implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new demographic details if no demographic details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicDetailsGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/single/codes", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetSingleByCodes implements IReturn<DemographicDetailsSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new demographic details if no demographic details is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DemographicDetailsGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollection implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DemographicDetailsGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollectionByIds implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsCollectionResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsGetCollectionByCodes implements IReturn<DemographicDetailsCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DemographicDetailsCollectionResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSave implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save/ids", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSaveWithIds implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The ID of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicDetailsPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/save/codes", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsSingleResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostSaveWithCodes implements IReturn<DemographicDetailsSingleResponse>
{
    /**
    * The demographic details to save.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The Code of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DemographicDetailsPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsSingleResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDelete implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The demographic details to delete.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteWithId implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/ids", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteWithIds implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The Patient ID of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/delete/codes", "DELETE")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsDeleteResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsDeleteByCodes implements IReturn<DemographicDetailsDeleteResponse>
{
    /**
    * The Patient Study Number of the demographic details to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DemographicDetailsDeleteResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidate implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    public constructor(init?: Partial<DemographicDetailsPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate/ids", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidateWithIds implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The ID of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Demographic Details
*/
// @Route("/opms/form/demographic-details/validate/codes", "POST")
// @Api(Description="Form - Demographic Details")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DemographicDetailsValidationResponse)", StatusCode=500)
// @DataContract
export class DemographicDetailsPostValidateWithCodes implements IReturn<DemographicDetailsValidationResponse>
{
    /**
    * The demographic details to validate.
    */
    // @DataMember(Order=1)
    public form: DemographicDetails;

    /**
    * The Code of the Institution associated with the demographic details to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the demographic details to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the demographic details to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the demographic details to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DemographicDetailsPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DemographicDetailsPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DemographicDetailsValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleById implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/ids", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByIds implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/single/codes", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetSingleByCodes implements IReturn<DiseaseAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new disease assessment if no disease assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollection implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DiseaseAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByIds implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentGetCollectionByCodes implements IReturn<DiseaseAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiseaseAssessmentCollectionResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSave implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithIds implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/save/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostSaveWithCodes implements IReturn<DiseaseAssessmentSingleResponse>
{
    /**
    * The disease assessment to save.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiseaseAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentSingleResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDelete implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The disease assessment to delete.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithId implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteWithIds implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentDeleteByCodes implements IReturn<DiseaseAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the disease assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiseaseAssessmentDeleteResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidate implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    public constructor(init?: Partial<DiseaseAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/ids", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithIds implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The ID of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Disease Assessment
*/
// @Route("/opms/form/disease-assessment/validate/codes", "POST")
// @Api(Description="Form - Disease Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiseaseAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class DiseaseAssessmentPostValidateWithCodes implements IReturn<DiseaseAssessmentValidationResponse>
{
    /**
    * The disease assessment to validate.
    */
    // @DataMember(Order=1)
    public form: DiseaseAssessment;

    /**
    * The Code of the Institution associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the disease assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiseaseAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiseaseAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiseaseAssessmentValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleById implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/ids", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByIds implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/single/codes", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetSingleByCodes implements IReturn<EligibilityConfirmationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility confirmation if no eligibility confirmation is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollection implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityConfirmationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByIds implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationGetCollectionByCodes implements IReturn<EligibilityConfirmationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityConfirmationCollectionResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSave implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithIds implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/save/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostSaveWithCodes implements IReturn<EligibilityConfirmationSingleResponse>
{
    /**
    * The eligibility confirmation to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityConfirmationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationSingleResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDelete implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The eligibility confirmation to delete.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithId implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteWithIds implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient ID of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationDeleteByCodes implements IReturn<EligibilityConfirmationDeleteResponse>
{
    /**
    * The Patient Study Number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityConfirmationDeleteResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidate implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    public constructor(init?: Partial<EligibilityConfirmationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/ids", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithIds implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The ID of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - Eligibility Confirmation
*/
// @Route("/opms/form/eligibility-confirmation/validate/codes", "POST")
// @Api(Description="Form - Eligibility Confirmation")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityConfirmationValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityConfirmationPostValidateWithCodes implements IReturn<EligibilityConfirmationValidationResponse>
{
    /**
    * The eligibility confirmation to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityConfirmation;

    /**
    * The Code of the Institution associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility confirmation to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityConfirmationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityConfirmationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityConfirmationValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/id/{Id}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleById implements IReturn<GeneralSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/ids", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByIds implements IReturn<GeneralSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/codes", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByCodes implements IReturn<GeneralSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollection implements IReturn<GeneralCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<GeneralGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByIds implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByCodes implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSave implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithIds implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithCodes implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDelete implements IReturn<GeneralDeleteResponse>
{
    /**
    * The general to delete.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/id/{Id}", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithId implements IReturn<GeneralDeleteResponse>
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/ids", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithIds implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/codes", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteByCodes implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidate implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithIds implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithCodes implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/id/{Id}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleById implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/ids", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByIds implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/single/codes", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetSingleByCodes implements IReturn<MedicalHistorySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new medical history if no medical history is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicalHistoryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollection implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicalHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByIds implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryGetCollectionByCodes implements IReturn<MedicalHistoryCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicalHistoryCollectionResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSave implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithIds implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/save/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistorySingleResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostSaveWithCodes implements IReturn<MedicalHistorySingleResponse>
{
    /**
    * The medical history to save.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicalHistoryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistorySingleResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDelete implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The medical history to delete.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithId implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/ids", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteWithIds implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient ID of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/delete/codes", "DELETE")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryDeleteByCodes implements IReturn<MedicalHistoryDeleteResponse>
{
    /**
    * The Patient Study Number of the medical history to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicalHistoryDeleteResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidate implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    public constructor(init?: Partial<MedicalHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/ids", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithIds implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The ID of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Medical History
*/
// @Route("/opms/form/medical-history/validate/codes", "POST")
// @Api(Description="Form - Medical History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicalHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class MedicalHistoryPostValidateWithCodes implements IReturn<MedicalHistoryValidationResponse>
{
    /**
    * The medical history to validate.
    */
    // @DataMember(Order=1)
    public form: MedicalHistory;

    /**
    * The Code of the Institution associated with the medical history to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the medical history to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the medical history to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the medical history to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicalHistoryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicalHistoryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicalHistoryValidationResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/single/id/{Id}", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormGetSingleById implements IReturn<ProFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/single/ids", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormGetSingleByIds implements IReturn<ProFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pro form if no pro form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/single/codes", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormGetSingleByCodes implements IReturn<ProFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pro form if no pro form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/collection", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ProFormGetCollection implements IReturn<ProFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormCollectionResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ProFormGetCollectionByIds implements IReturn<ProFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormCollectionResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormCollectionResponse)", StatusCode=500)
// @DataContract
export class ProFormGetCollectionByCodes implements IReturn<ProFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProFormCollectionResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/save", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormPostSave implements IReturn<ProFormSingleResponse>
{
    /**
    * The pro form to save.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    public constructor(init?: Partial<ProFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/save/ids", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormPostSaveWithIds implements IReturn<ProFormSingleResponse>
{
    /**
    * The pro form to save.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    /**
    * The ID of the Institution associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/save/codes", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormSingleResponse)", StatusCode=500)
// @DataContract
export class ProFormPostSaveWithCodes implements IReturn<ProFormSingleResponse>
{
    /**
    * The pro form to save.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    /**
    * The Code of the Institution associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormSingleResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/delete", "DELETE")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ProFormDelete implements IReturn<ProFormDeleteResponse>
{
    /**
    * The pro form to delete.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    public constructor(init?: Partial<ProFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProFormDeleteResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ProFormDeleteWithId implements IReturn<ProFormDeleteResponse>
{
    /**
    * The ID of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProFormDeleteResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/delete/ids", "DELETE")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ProFormDeleteWithIds implements IReturn<ProFormDeleteResponse>
{
    /**
    * The Patient ID of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProFormDeleteResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/delete/codes", "DELETE")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormDeleteResponse)", StatusCode=500)
// @DataContract
export class ProFormDeleteByCodes implements IReturn<ProFormDeleteResponse>
{
    /**
    * The Patient Study Number of the pro form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProFormDeleteResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/validate", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=500)
// @DataContract
export class ProFormPostValidate implements IReturn<ProFormValidationResponse>
{
    /**
    * The pro form to validate.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    public constructor(init?: Partial<ProFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormValidationResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/validate/ids", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=500)
// @DataContract
export class ProFormPostValidateWithIds implements IReturn<ProFormValidationResponse>
{
    /**
    * The pro form to validate.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    /**
    * The ID of the Institution associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormValidationResponse(); }
}

/**
* Form - Pro Form
*/
// @Route("/opms/form/pro-form/validate/codes", "POST")
// @Api(Description="Form - Pro Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProFormValidationResponse)", StatusCode=500)
// @DataContract
export class ProFormPostValidateWithCodes implements IReturn<ProFormValidationResponse>
{
    /**
    * The pro form to validate.
    */
    // @DataMember(Order=1)
    public form: ProForm;

    /**
    * The Code of the Institution associated with the pro form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pro form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pro form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pro form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProFormValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The source document to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleById implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentFormGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/ids", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleByIds implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document form if no source document form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentFormGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/single/codes", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetSingleByCodes implements IReturn<SourceDocumentFormSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document form if no source document form is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentFormGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollection implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentFormGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollectionByIds implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormGetCollectionByCodes implements IReturn<SourceDocumentFormCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentFormCollectionResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSave implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save/ids", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSaveWithIds implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The ID of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentFormPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/save/codes", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostSaveWithCodes implements IReturn<SourceDocumentFormSingleResponse>
{
    /**
    * The source document form to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The Code of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentFormPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormSingleResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDelete implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The source document form to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteWithId implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/ids", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteWithIds implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The Patient ID of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/delete/codes", "DELETE")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormDeleteByCodes implements IReturn<SourceDocumentFormDeleteResponse>
{
    /**
    * The Patient Study Number of the source document form to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentFormDeleteResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidate implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    public constructor(init?: Partial<SourceDocumentFormPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate/ids", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidateWithIds implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The ID of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

/**
* Form - Source Document Form
*/
// @Route("/opms/form/source-document-form/validate/codes", "POST")
// @Api(Description="Form - Source Document Form")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentFormValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentFormPostValidateWithCodes implements IReturn<SourceDocumentFormValidationResponse>
{
    /**
    * The source document form to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocumentForm;

    /**
    * The Code of the Institution associated with the source document form to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document form to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document form to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document form to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentFormPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentFormPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentFormValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetUserNames implements IReturn<GetUserNamesResponse>, IGet
{
    public instId?: number;
    public roles: number[];

    public constructor(init?: Partial<GetUserNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUserNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserNamesResponse(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

